"use client"

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import TitleBar from "../../layout/TitleBar"
import Layout from "../../layout/Layout"
import {
  getBooking,
  updateBookingStatus,
  getAvailableVehicles,
  updateDriver,
  addNote,
  getCart,
  getBookingsByCartID,
} from "../../../actions/admin/booking"
import { CalendarContainer } from "react-datepicker"
import {
  chargeToken,
  chargeTokenEditedFee,
  chargeTokenAdditionalFee,
  chargeTokenByCartId,
  chargeTokenWithRequestedChanges,
} from "../../../actions/admin/flywire"
import { setAlert } from "../../../actions/alert"
import Select from "react-select"
import { Link, useHistory } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import moment from "../../../utils/customMoment"
import { getAllActiveVehicles } from "../../../actions/admin/vehicle"
import { bookingStatusOptions } from "../../../utils/dataLists"
import { getAllDrivers } from "../../../actions/admin/driver"
import { getTemplate, getTemplates, sendMail, sendMailChangeCCInfo } from "../../../actions/admin/mail"
import { getSetting } from "../../../actions/admin/settings"
import { getBookingCancelEmailTemplate } from "../../../utils/EmailTemplate"
import { useLoading } from "../../../components/LoadingContext"
import ModalConfirmation from "../../modal/ModalConfirmation"

import back_icon from "../../../images/back_icon.svg"

const ManageBookingV2 = ({ match }) => {
  const newPaymentStyle = false;
  const settings = useSelector((state) => state.setting)
  const { lang } = settings
  const history = useHistory()
  const [activeVehicles, setActiveVehicles] = useState([])
  const [activeDrivers, setActiveDrivers] = useState([])
  const [ddltemplate, setDDlTemplates] = useState(false)
  const [selectedOption, setSelectedOption] = useState([])
  const [driverSelectedOption, setDriverSelectedOption] = useState([])
  const [ddltemplateOption, setddlTemplateOption] = useState(null)
  const { showLoading, closeLoading } = useLoading()

  const dispatch = useDispatch()
  const template_state = useSelector((state) => state.template)
  const { templates } = template_state || {} // Add null check
  const bookingState = useSelector((state) => state.booking)
  const { loading, booking } = bookingState || {} // Add null check
  const [style, setStyle] = useState("")
  const [note, setNote] = useState("")
  const [bookingStatus, setBookingStatus] = useState(null)
  const [isDisabled, setIsDisabled] = useState(false)
  const [subject, setSubject] = useState("")
  const [updatedHtmlstate, setUpdatedHtmlstate] = useState(null)

  const [labelData, setLabelData] = useState({})

  const [editHourUsage, setEditHourUsage] = useState(false)
  const [newHourUsage, setNewHourUsage] = useState(null)

  const [checkChargeLoading, setcheckChargeLoading] = useState(true)
  const [bookingsWithCartIdLength, setBookingsWithCartIdLength] = useState(1)
  const [chargeAccount, setChargeAccount] = useState("")

  const [addChildSeat, setAddChildSeat] = useState(false)
  const [newChildSeat, setNewChildSeat] = useState({
    infant: 0,
    toddler: 0,
    child: 0,
  })

  //handle add setNewChildSeat increment
  const handleAddChildSeat = (type) => {
    console.log(type)
    setNewChildSeat({
      ...newChildSeat,
      [type]: newChildSeat[type] + 1,
    })
  }

  //handle add setNewChildSeat decrement
  const handleRemoveChildSeat = (type) => {
    if (newChildSeat[type] > 0) {
      setNewChildSeat({
        ...newChildSeat,
        [type]: newChildSeat[type] - 1,
      })
    }
  }

  const [newTotalBooking, setNewTotalBooking] = useState(0)

  useEffect(() => {
    dispatch(getSetting())
    fetchActiveVehicles(activeVehicleCallBackFunc)
  }, [])

  useEffect(() => {
    fetchActiveDrivers(activeDriversCallBackFunc)
    dispatch(getTemplates())
  }, [])

  useEffect(() => {
    if (!templates) return
    const data = templates.map((row) => {
      return {
        value: row._id,
        label: row.templatename,
        subject: row.subject,
      }
    })
    setDDlTemplates(data)
  }, [templates])

  const activeDriversCallBackFunc = (e_data) => {
    setActiveDrivers(e_data)
  }

  const fetchActiveDrivers = async (callback) => {
    const vt_data = await getAllDrivers()
    const data = vt_data.map((row) => {
      return {
        value: row._id,
        label: row.name,
      }
    })
    callback(data)
  }

  const activeVehicleCallBackFunc = (e_data) => {
    setActiveVehicles(e_data)
  }

  const float2int = (value) => {
    return value | 0
  }

  const roundSixtyMinValue = (value) => {
    const inHours = value / 60
    const onlydecemal = inHours % 1
    const onlyHour = float2int(inHours)
    var f_val = 0
    if (onlydecemal > 0 && onlydecemal < 0.5) {
      f_val = onlyHour + 0.5
    } else if (onlydecemal > 0.5 && onlydecemal < 1) {
      f_val = onlyHour + 1
    } else if (onlydecemal === 0) {
      f_val = onlyHour
    } else if (onlydecemal === 0.5) {
      f_val = inHours
    }
    return f_val
  }

  const fetchActiveVehicles = async (callback) => {
    const vt_data = await getAllActiveVehicles()
    const data = vt_data.map((row) => {
      return {
        value: row._id,
        label: row.vehiclename,
      }
    })
    callback(data)
  }

  useEffect(() => {
    showLoading(true)
    dispatch(getBooking(match?.params?.id)) // Add null check
  }, [])

  const checkHasTokyo = async (pickupLocation, destinations) => {
    var has_tokyo = false
    if (
      pickupLocation?.prefecture === "Tokyo" ||
      pickupLocation?.prefecture === "東京" ||
      pickupLocation?.prefecture === "東京中" ||
      pickupLocation?.prefecture === "東京都" ||
      destinations?.[destinations.length - 1]?.prefecture === "Tokyo" ||
      destinations?.[destinations.length - 1]?.prefecture === "東京" ||
      destinations?.[destinations.length - 1]?.prefecture === "東京中" ||
      destinations?.[destinations.length - 1]?.prefecture === "東京都"
    ) {
      has_tokyo = true
    }
    return has_tokyo
  }

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        setcheckChargeLoading(true)
        const bookingsData = await getBookingsByCartID(booking?.cartID)
        console.log(bookingsData)
        setBookingsWithCartIdLength(bookingsData?.length || 1)

        const hasTokyo = await checkHasTokyo(booking?.pickupLocation, booking?.destinations || [])

        if (hasTokyo) {
          setChargeAccount("TLF")
        } else {
          setChargeAccount("JPT")
        }
      } catch (error) {
        console.error("Failed to fetch bookings:", error)
      } finally {
        setcheckChargeLoading(false)
      }
    }

    if (booking?.cartID) {
      fetchBookings()
    }
  }, [booking])

  useEffect(() => {
    if (!loading && booking !== null) {
      booking.bookingInfo?.forEach((val, ind) => {
        var newArrayDriver
        var newArray
        if (val?.driver) {
          const d_opt = activeDrivers.find((opd) => opd.value === val?.driver?._id)
          newArrayDriver = [...driverSelectedOption]
          newArrayDriver[ind] = d_opt
          setDriverSelectedOption(newArrayDriver)
        } else {
          newArrayDriver = [...driverSelectedOption]
          newArrayDriver[ind] = null
          setDriverSelectedOption(newArrayDriver)
        }

        if (val?.vehicle) {
          console.log("((((((((((((((((((((((")
          console.log(val?.vehicle)
          const opt = activeVehicles.find((op) => op.label === val?.vehicle?.vehiclename)
          newArray = [...selectedOption]
          newArray[ind] = opt
          setSelectedOption(newArray)
        } else {
          newArray = [...selectedOption]
          newArray[ind] = null
          setSelectedOption(newArray)
        }
      })

      var bkStatus = loading || !booking?.bookingStatus ? "" : booking?.bookingStatus
      const bk_opt = bookingStatusOptions.find((op) => op.label === bkStatus)
      setBookingStatus(bk_opt)
      switch (bkStatus) {
        case "CONFIRMED":
          setStyle("badge bg-success py-2")
          break
        case "Payment Pending":
          setStyle("badge bg-primary py-2")
          break
        case "Inquiry":
          setStyle("badge bg-warning py-2")
          break
        case "Cancelled":
          setStyle("badge bg-danger py-2")
          break
        default:
          setStyle("badge bg-dark py-2")
          break
      }
      console.log(booking)

      const isVehicleControlerDisabled =
        booking?.bookingStatus === "Inquiry" ||
          booking?.bookingStatus === "Payment Pending" ||
          booking?.bookingStatus === "Cancelled"
          ? true
          : false

      if (booking?.cartID !== null) dispatch(getCart(booking?.cartID))

      setIsDisabled(isVehicleControlerDisabled)
      closeLoading(true)
    }
  }, [booking])

  const addNotes = () => {
    showLoading(true)
    if (note !== "") {
      const note_arr = [{ note: note, isAdmin: true }]

      dispatch(addNote(labelData?._id, note_arr))
    }
    closeLoading(true)
    setNote("")
  }

  const updateBookingDriver = async (index = 0) => {
    showLoading(true)

    if (driverSelectedOption[index] !== null && labelData?._id)
      dispatch(updateDriver(labelData._id, driverSelectedOption[index].value, index))
    closeLoading(true)
  }

  const updateBooking = async () => {
    showLoading(true)
    // get available vehicles

    console.log(booking?.bookingStatus)
    console.log(bookingStatus?.label)
    if (booking?.bookingStatus !== bookingStatus?.label && booking?._id) {
      if (bookingStatus?.label === "CONFIRMED") {
        const available_vehicles = await getAvailableVehicles(
          moment(booking?.tripStartTime),
          moment(booking?.garageArivalTimeWithAdditionalTime),
          booking?.vehicleTypeName,
        )
        if (available_vehicles?.length > 0) {
          const available_vehicle_id = available_vehicles[0]._id
          dispatch(updateBookingStatus(booking._id, bookingStatus.label, available_vehicle_id))
        } else {
          dispatch(setAlert("Vehicles are not available for the date.", "danger"))
        }
      } else if (
        bookingStatus?.label === "CANCELLED" ||
        bookingStatus?.label === "INQUIRY" ||
        bookingStatus?.label === "PAYMENT_PENDING"
      ) {
        dispatch(updateBookingStatus(booking._id, bookingStatus.label, null))
      }
    }
    closeLoading(true)
  }

  const sendMailToClient = async () => {
    showLoading(true)

    if (labelData?.email) {
      const mailRes = await sendMail(
        labelData.email,
        "Your booking has been cancelled",
        getBookingCancelEmailTemplate(labelData),
      )

      if (mailRes?.msg === "success") {
        dispatch(setAlert("Booking cancelation email sent to the customer.", "success"))
      } else {
        dispatch(setAlert("email is not sent", "error"))
      }
    }
    closeLoading(true)
  }

  const sendCustomMailToClient = async () => {
    showLoading(true)
    if (ddltemplateOption?.value) {
      const selectedTemplate = await getTemplate(ddltemplateOption.value)
      var htmltemp = selectedTemplate?.htmlemail || ""
      if (htmltemp.includes("{{pickupLocation}}"))
        htmltemp = htmltemp.replace("{{pickupLocation}}", booking?.pickupLocation?.label || "")
      if (htmltemp.includes("{{dropofflocation}}"))
        htmltemp = htmltemp.replace(
          "{{dropofflocation}}",
          booking?.destinations
            ?.map((d) => {
              return d?.label || ""
            })
            .join(" / ") || "",
        )
      if (htmltemp.includes("{{drivername}}"))
        htmltemp = htmltemp.replace("{{drivername}}", booking?.driver ? booking.driver?.name : "N/A")
      if (htmltemp.includes("{{vehiclename}}"))
        htmltemp = htmltemp.replace("{{vehiclename}}", booking?.vehicle?.vehiclename || "")
      if (htmltemp.includes("{{vehicletype}}"))
        htmltemp = htmltemp.replace("{{vehicletype}}", booking?.vehicle?.vehicletype || "")
      if (htmltemp.includes("{{pickuptime}}"))
        htmltemp = htmltemp.replace(
          "{{pickuptime}}",
          booking?.travelDate ? moment(booking.travelDate).format("YYYY/MM/DD hh:m Am") : "",
        )
      if (htmltemp.includes("{{dropofftime}}") && booking?.bookingInfo?.[0]?.cutomerDropOffTimeWithAdditinalTime)
        htmltemp = htmltemp.replace(
          "{{dropofftime}}",
          moment(booking.bookingInfo[0].cutomerDropOffTimeWithAdditinalTime).format("YYYY/MM/DD hh:m Am"),
        )
      if (htmltemp.includes("{{customername}}"))
        htmltemp = htmltemp.replace("{{customername}}", booking?.customer?.name || "")
      if (htmltemp.includes("{{cart}}")) htmltemp = htmltemp.replace("{{cart}}", getAdminCart())
      if (htmltemp.includes("{{packagename}}"))
        htmltemp = htmltemp.replace(
          "{{packagename}}",
          booking?.activePackage ? booking.activePackage?.packagename : "N/A",
        )
      if (htmltemp.includes("{{totalcost}}"))
        htmltemp = htmltemp.replace("{{totalcost}}", booking?.bookingAmountInfo?.total || "")

      if (htmltemp.includes("{{dropoffFlightno}}"))
        htmltemp = htmltemp.replace("{{dropoffFlightno}}", booking?.dropoffFlightNo || "")
      if (htmltemp.includes("{{pickupFlightno}}"))
        htmltemp = htmltemp.replace("{{pickupFlightno}}", booking?.pickupFlightNo || "")
      if (htmltemp.includes("{{vehicleregistration}}"))
        htmltemp = htmltemp.replace("{{vehicleregistration}}", booking?.vehicle?.registration || "")
      setUpdatedHtmlstate(htmltemp)
    }
    closeLoading(true)
  }

  useEffect(() => {
    if (updatedHtmlstate && labelData?.email) sendUpdatedEmailTemplate()
  }, [updatedHtmlstate])

  const sendUpdatedEmailTemplate = async () => {
    await sendMail(labelData.email, subject, updatedHtmlstate)
    dispatch(setAlert("Email has been sent.", "success"))
  }

  const getAdminCart = () => {
    var crt = ""

    return crt
  }

  const chargeTokenHandler = async (id, byCartId = false, customFeeType = "", customFee = 0) => {
    if (!id) return

    showLoading(true)
    if (byCartId) {
      const chargeTokenByCartIdRes = await chargeTokenByCartId(id)
      closeLoading(true)

      if (chargeTokenByCartIdRes?.data?.status === "success") {
        window.location.reload()
      } else {
        // console.log(chargeTokenByCartIdRes.data);
      }
    } else {
      if (customFeeType === "edited") {
        const chargeTokenRes = await chargeTokenEditedFee(id, customFee)
        closeLoading(true)

        if (chargeTokenRes?.data?.status === "success") {
          window.location.reload()
        } else {
          // console.log(chargeTokenRes.data);
        }
      } else if (customFeeType === "additional") {
        const chargeTokenRes = await chargeTokenAdditionalFee(id, customFee)
        closeLoading(true)

        if (chargeTokenRes?.data?.status === "success") {
          window.location.reload()
        } else {
          // console.log(chargeTokenRes.data);
        }
      } else {
        const chargeTokenRes = await chargeToken(id)
        closeLoading(true)

        if (chargeTokenRes?.data?.status === "success") {
          window.location.reload()
        } else {
          // console.log(chargeTokenRes.data);
        }
      }
    }
  }

  const chargeTokenWithRequestedChangesHandler = async (id) => {
    if (!id) return

    showLoading(true)
    const chargeTokenRes = await chargeTokenWithRequestedChanges(id)
    closeLoading(true)

    if (chargeTokenRes?.data?.status === "success") {
      window.location.reload()
    } else {
      // console.log(chargeTokenRes.data);
    }
  }

  const [showModalConfirmation, setShowModalConfirmation] = useState(false)
  const [showModalConfirmationCCInfo, setShowModalConfirmationCCInfo] = useState(false)
  const [modalConfirmationText, setModalConfirmationText] = useState("")
  const [continueAction, setContinueAction] = useState(() => () => { })

  // Open the confirmation modal and set the message
  const handleOpenModalConfirmation = (text, action) => {
    setModalConfirmationText(text)
    setContinueAction(() => action) // Set the continue action
    setShowModalConfirmation(true)
  }

  const handleOpenModalConfirmationUpdateCCInfo = (text, action) => {
    setShowModalConfirmationCCInfo(true)
  }

  // Close the confirmation modal
  const handleCloseModalConfirmation = () => {
    setShowModalConfirmation(false)
  }

  // Close the confirmation modal
  const handleCloseModalConfirmationUpdateCCInfo = () => {
    setShowModalConfirmationCCInfo(false)
  }

  // Function to handle the continuation action
  const handleContinue = () => {
    continueAction() // Execute the stored continue action
    handleCloseModalConfirmation() // Close the modal after action
  }

  const dateTimeContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div style={{ position: "relative" }}>{children}</div>
      </CalendarContainer>
    )
  }

  const handleChangeUsageDuration = async () => {
    // var reqParamsAvailableVehicle = {
    //   pickupLocation: booking.pickuplocation,
    //   dropoffLocations: booking.dropofflocations,
    //   bookingType: booking.bookingType,
    //   travelDate: booking.traveldate,
    //   pickupTime: booking.pickuptime,
    //   pax: booking.pax,
    //   luggage: booking.lugage,
    //   flightTracking: (booking.flightTracking || booking.isAirportTransfer),
    // }
    // if (booking.bookingType === "byTheHour") {
    //   reqParamsAvailableVehicle = {
    //     ...reqParamsAvailableVehicle,
    //     usageDuration: sfd.duration,
    //     travelArea: sfd.travelArea
    //   }
    // }
    // const availableVehicle = await getAvailableVehicle(reqParamsAvailableVehicle);
    // if (availableVehicle.code !== 200 || availableVehicle.desc !== "SUCCESS" || availableVehicle.data === null) {
    // setmodalAlertText("Internal Server Error");
    // setShowModalAlert(true);
    // }
    // const {
    //   total_time,
    //   totalNightHours,
    //   startFromGarage,
    //   travelDateWithPickupTime,
    //   customerDropoffDateTime,
    //   garageArrivalTime,
    //   totalDistance,
    //   garage,
    //   additionalTime,
    //   breakTime,
    //   flightTrackingTime,
    //   usageDuration,
    //   billedDuration,
    //   appliedPackage,
    //   availableVehicleTypes,
    // } = availableVehicle.data;
    // console.log(availableVehicle.data)
  }

  // Close the confirmation modal
  const sumAdditionalFee = () => {
    var childSeatPrice = booking?.bookingInfo?.reduce((sum, booking) => sum + (booking?.childSeatPrice || 0), 0) || 0
    var meetAssistPrice = booking?.meetAssist?.reduce((sum, item) => sum + (item?.meetAssistPrice || 0), 0) || 0
    // console.log(childSeatPrice, meetAssistPrice);
    return (childSeatPrice + meetAssistPrice).toLocaleString()
  }

  const sendMailChangeCCInfoHandler = async (cartID) => {
    if (!cartID) return

    setShowModalConfirmationCCInfo(false)
    showLoading(true)
    const sendMailChangeCCInfoRes = await sendMailChangeCCInfo(cartID)
    closeLoading(true)

    if (sendMailChangeCCInfoRes?.data?.status === "success") {
      // console.log("success")
      // console.log(sendMailChangeCCInfoRes.data);
    } else {
      // console.log(sendMailChangeCCInfoRes.data);
    }
  }

  const formatDate = (isoString, includeTime = true, includeDay = false) => {
    if (!isoString) return "-"

    const date = moment.utc(isoString) // Ensure UTC, no local conversion

    let formattedDate = date.format("YYYY/MM/DD") // Default format: 2025/02/01

    if (includeDay) {
      formattedDate += ` (${date.format("ddd")})` // Adds day abbreviation (e.g., "Sat")
    }

    if (includeTime) {
      formattedDate += ` ${date.format("HH:mm")}` // Adds time in 24-hour format
    }

    return formattedDate
  }

  // Function to extract and format child seat information
  const getChildSeatSummary = (data) => {
    if (!data?.bookingInfo) return "-"

    // Initialize counters for each seat type
    const seatCounts = {
      Infant: 0,
      Toddler: 0,
      Child: 0,
    }

    // Loop through each booking
    data.bookingInfo.forEach((booking) => {
      // Loop through each child seat in the booking
      booking?.childSeat?.forEach((seat) => {
        // Add the count to our running total
        if (seat?.childSeatType) {
          seatCounts[seat.childSeatType] += seat.childSeatCount || 0
        }
      })
    })

    // Format the output string
    const result = Object.entries(seatCounts)
      .map(([type, count]) => `${type} x ${count}`)
      .join(", ")

    return result || "-"
  }


  const formatTimeDuration = (totalMinutes) => {
    // Calculate exact hours and minutes
    const exactHours = Math.floor(totalMinutes / 60);
    const exactMinutes = totalMinutes % 60;

    // Calculate rounded hours (ceiling to nearest 0.5)
    const decimalHours = totalMinutes / 60;
    // Multiply by 2, ceil, then divide by 2 to get 0.5 increments
    const roundedToHalfHour = Math.ceil(decimalHours * 2) / 2;

    // Format the first part (rounded hours)
    const hoursDisplay = roundedToHalfHour % 1 === 0
      ? `${roundedToHalfHour} Hours`
      : `${roundedToHalfHour} Hours`;

    // Format the second part (exact hours and minutes)
    const exactTimeDisplay = `${exactHours}h${exactMinutes}m`;

    // Combine both parts
    return `${hoursDisplay} (${exactTimeDisplay})`;
  }


  return (
    <Layout>
      <ModalConfirmation
        handleClose={handleCloseModalConfirmation}
        show={showModalConfirmation}
        text={modalConfirmationText}
        handleContinue={handleContinue}
      />

      <ModalConfirmation
        handleClose={handleCloseModalConfirmationUpdateCCInfo}
        show={showModalConfirmationCCInfo}
        text="Send user email to update card info?"
        handleContinue={() => sendMailChangeCCInfoHandler(booking?.cartID)}
      />
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <TitleBar title={!labelData?.isAbandoned ? `Manage Booking` : `Abandoned cart`} />
        {loading !== true && booking != null && (
          <div className="container-fluid mb-5">
            <div className="d-flex align-items-center mt-3 py-3">
              <div className="me-3">
                <Link to={booking?.isAbandoned ? `/admin/bookinglist` : `/admin/abandentcartlist`}>
                  <img src={back_icon || "/placeholder.svg"} alt="" srcSet="" />
                </Link>
              </div>
              <h3 className="m-0">Booking # {booking?.cartID || "-"}</h3>
              <div className="ms-auto">
                <div
                  className={`container rounded px-3 py-2 ${booking?.bookingStatus === "CONFIRMED"
                    ? "bg-success"
                    : booking?.bookingStatus === "CANCELLED"
                      ? "bg-danger"
                      : "bg-grey"
                    }`}
                >
                  <p className="m-0 text-white">{booking?.bookingStatus || "-"}</p>
                </div>
              </div>
            </div>
            <hr className="divider-gold" />
            <div className="d-flex gap-4 mb-3">
              <h6 className={`fw-700 border-bottom border-dark pointer`}>Booking Details</h6>
              <h6 className={`fw-700 text-black-30 pointer`}>Booking Management</h6>
            </div>

            <h5 className={`fw-700`}>Customer Information</h5>
            <div className="border rounded mb-5">
              <div className="row align-items-start gap-5 p-3">
                <div className="col-auto">
                  <b className="fs-14">
                    Passenger <br /> Information
                  </b>
                </div>
                <div className="col-auto">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Prefix</p>
                  <b className="fs-14">{booking?.passengerPronounced || "-"}</b>
                </div>
                <div className="col-auto">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Passenger's Name</p>
                  <b className="fs-14">{booking?.passengerName || "-"}</b>
                </div>
                <div className="col-auto">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Passengers</p>
                  <b className="fs-14"> Adult: {booking?.pax?.[0]?.paxcount || 0}</b>
                  <div className="d-flex">
                    <p className="m-0 text-A7A7A7">Child : &nbsp;</p>
                    <p className="m-0">{booking?.pax?.[1]?.paxcount || 0} &nbsp;/ &nbsp;</p>
                    <p className="m-0 text-A7A7A7">Toddler : &nbsp;</p>
                    <p className="m-0">{booking?.pax?.[2]?.paxcount || 0} &nbsp;/ &nbsp;</p>
                    <p className="m-0 text-A7A7A7">Infant : &nbsp;</p>
                    <p className="m-0">{booking?.pax?.[3]?.paxcount || 0} &nbsp;/ &nbsp;</p>
                  </div>
                </div>
                <div className="col-auto">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Luggage</p>
                  <b className="fs-14">Check-in Size : {booking?.luggage?.[0]?.luggagecount || 0}</b>
                  <br />
                  <b className="fs-14">Cabin Size : {booking?.luggage?.[1]?.luggagecount || 0}</b>
                </div>
                <div className="col-auto">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Special Request</p>
                  {booking?.message ? (
                    booking.message.map((d) => (
                      <b className="fs-14" key={d?._id}>
                        {d?.note || ""}
                      </b>
                    ))
                  ) : (
                    <>-</>
                  )}
                </div>
              </div>
              <hr className="hr-E8E8E8 m-0" />
              <div className="row align-items-start gap-5 p-3">
                <div className="col-auto">
                  <b className="fs-14">
                    Billing <br /> Information
                  </b>
                </div>
                <div className="col-auto">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Prefix</p>
                  <b className="fs-14">{booking?.customer?.pronounced || "-"}</b>
                </div>
                <div className="col-auto">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Reservation Holder</p>
                  <b className="fs-14">{booking?.customer?.name || "-"}</b>
                </div>
                <div className="col-auto">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Email</p>
                  <b className="fs-14">{booking?.customer?.email || "-"}</b>
                </div>
                <div className="col-auto">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Phone No.</p>
                  <b className="fs-14">
                    {booking?.customer?.prefix_number || ""} {booking?.customer?.phone || "-"}
                  </b>
                </div>
                <div className="col-auto">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Country</p>
                  <b className="fs-14">{booking?.customer?.country || "-"}</b>
                </div>
                <div className="col-auto">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Postal Code</p>
                  <b className="fs-14">{booking?.customer?.postal_code || "-"}</b>
                </div>
              </div>
            </div>

            <h5 className={`fw-700`}>Booking Details</h5>
            <div className="border rounded mb-2">
              <div className="row align-items-start gap-5 p-3">
                <div className="col-4 align-items-center">
                  <div className="d-flex gap-2 align-items-center">
                    <p className="m-0">Status</p>
                    <div className="col">
                      <Select
                        classNamePrefix="select pick-up location"
                        autoComplete="off"
                        value={bookingStatus}
                        onChange={(e) => {
                          console.log(e)
                          setBookingStatus(e)
                        }}
                        options={bookingStatusOptions}
                      />
                    </div>
                    <div className="col-auto">
                      <button
                        // type="submit"
                        disabled={booking?.isAbandoned}
                        className="btn ms-1 rounded border-dark"
                        onClick={updateBooking}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Booking Date</p>
                  <b className="fs-14">{formatDate(booking?.date)}</b>
                </div>

                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Booking Channel</p>
                  <b className="fs-14">{booking?.fromQuotation === true ? "Admin (Quotation)" : "Website"}</b>
                </div>

                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Type</p>
                  <b className="fs-14">{booking?.customer?.userId?.role === "individual" ? "User" : "Business"}</b>
                </div>
              </div>
            </div>
            <div className="border rounded mb-5">
              <div className="row align-items-start gap-4 p-3">
                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Type of Transfer</p>
                  <b className="fs-14">
                    {booking?.isAirportTransfer === true
                      ? "Airport Transfer"
                      : booking?.bookingType === "specifiedDestination"
                        ? "Specified Destination"
                        : "By The Hour"}
                  </b>
                </div>
                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Travel Date</p>
                  <b className="fs-14">{formatDate(booking?.travelDate, false, true)}</b>
                </div>
                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Travel Area</p>
                  <b className="fs-14">{booking?.bookingInfo?.[0]?.travelArea?.trim() || "-"}</b>
                </div>
                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Billed Duration</p>
                  <b className="fs-14">{booking?.dataVersion === "v3" ? formatTimeDuration(booking?.billedDuration || 0) : `${booking?.billedDuration || 0} Hours`}</b>
                </div>
                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Total Distance</p>
                  <b className="fs-14">{(booking?.totalDistance || 0) / 1000} KM</b>
                </div>
              </div>
              <div className="row align-items-start gap-4 p-3">
                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Pick-up Time</p>
                  <b className="fs-14">
                    {booking?.travelDate && booking?.pickupTime
                      ? formatDate(`${booking.travelDate.split("T")[0]}T${booking.pickupTime}:00.000Z`, true, true)
                      : "-"}
                  </b>
                </div>
                <div className="col-6">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Pick-up Location</p>
                  <b className="fs-14">{booking?.pickupLocation?.formatted_address || "-"}</b>
                </div>
                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Garage Dispatch Time</p>
                  <b className="fs-14">{formatDate(booking?.startFromGarage, true, true)}</b>
                </div>
              </div>
              <div className="row align-items-start gap-4 p-3">
                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Estimated Drop-off Time</p>
                  <b className="fs-14">{formatDate(booking?.customerDropoffDateTime, true, true)}</b>
                </div>
                <div className="col-6">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Drop-off Location</p>
                  {booking?.destinations ? (
                    booking.destinations.map((d, index) => (
                      <>
                        <b className="fs-14" key={d?._id || index}>
                          ({index + 1}){d?.label || ""}
                        </b><br />
                      </>
                    ))
                  ) : (
                    <>-</>
                  )}
                </div>
                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Garage Return Time</p>
                  <b className="fs-14">{formatDate(booking?.garageArivalTime, true, true)}</b>
                </div>
              </div>
              <hr className="hr-E8E8E8 m-0" />
              <div className="row align-items-start gap-4 p-3">
                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Airport Transfer Package</p>
                  <b className="fs-14">{booking?.isAirportTransfer === true ? "Yes" : "-"}</b>
                </div>
                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Drop-off Flight No.</p>
                  <b className="fs-14">{booking?.dropoffFlightNo?.trim() || "-"}</b>
                </div>
                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Pick-up Flight No.</p>
                  <b className="fs-14">{booking?.pickupFlightNo?.trim() || "-"}</b>
                </div>
                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Meet & Assist Service</p>
                  <b className="fs-14">
                    {booking?.meetAssist?.every((item) => item?.meetAssistName === "none")
                      ? "-"
                      : booking?.meetAssist?.map((item, index) => {
                        if (!item || item?.meetAssistName === "none") {
                          return null
                        }
                        const type = item?.meetAssistType === "arrival" ? "Arrival" : "Departure"
                        return <span key={index}>Meet & Assist ({type})</span>
                      })}
                  </b>
                </div>
                <div className="col-2">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Nameboard</p>
                  <b className="fs-14">
                    {booking?.pickupSign ? (booking?.pickupSign !== "null null" ? booking?.pickupSign : "-") : "-"}
                  </b>
                </div>
              </div>
              <div className="row align-items-start gap-5 p-3">
                <div className="col-auto">
                  <p className="m-0 fw-700 text-grey-200 fs-12 ">Child Seat</p>
                  <b className="fs-14">{getChildSeatSummary(booking)}</b>
                </div>
              </div>
            </div>

            <h5 className={`fw-700`}>Time & Cost Details</h5>
            <div className="mb-5">
              <div className="border rounded">
                <div className="row align-items-start p-3">
                  <div className="col-12 col-md-2">
                    <p className="m-0 fw-700 text-grey-200 fs-12">Total Time</p>
                    <b className="fs-14">{booking?.dataVersion === "v3" ? formatTimeDuration(booking?.billedDuration || 0) : `${booking?.billedDuration || 0} Hours`}</b>
                  </div>
                  <div className="col-12 col-md-2">
                    <p className="m-0 fw-700 text-grey-200 fs-12">Total Driving Time</p>
                    <b className="fs-14">{booking?.dataVersion === "v3" ? formatTimeDuration(booking?.usageDuration || 0) : `${booking?.usageDuration || 0} Hours`}</b>
                  </div>
                  <div className="col-12 col-md-2">
                    <p className="m-0 fw-700 text-grey-200 fs-12">Driver's Break Time</p>
                    <b className="fs-14">
                      {`${Math.ceil((booking?.breakTime ?? 0) + (booking?.additionalTime ?? 0))} Minutes`}
                    </b>
                  </div>
                  <div className="col-12 col-md-2">
                    <p className="m-0 fw-700 text-grey-200 fs-12">Waiting Time</p>
                    <b className="fs-14">{Math.floor(booking?.flightTrackingTime ?? 0)} Minutes</b>
                  </div>
                  <div className="col-12 col-md-2">
                    <p className="m-0 fw-700 text-grey-200 fs-12">Late-night Usage</p>
                    <b className="fs-14">{booking?.dataVersion === "v3" ? formatTimeDuration(booking?.nightHours ?? 0) : `${booking?.nightHours ?? 0} Hours`}</b>
                  </div>
                </div>

                {booking?.bookingInfo?.map((item, index) => (
                  <div key={index}>
                    <hr className="hr-E8E8E8 m-0" />
                    <div className="row align-items-start p-3">
                      <div className="col-12 col-md-2">
                        <p className="m-0 fw-700 text-grey-200 fs-12">Vehicle Type {index + 1}</p>
                        <b className="fs-14">{item?.vehicleTypeName || "-"}</b>
                      </div>
                      <div className="col-12 col-md-2">
                        <p className="m-0 fw-700 text-grey-200 fs-12">Base Price</p>
                        <b className="fs-14">{(item?.baseprice || 0).toLocaleString()} JPY</b>
                      </div>
                      <div className="col-12 col-md-2">
                        <p className="m-0 fw-700 text-grey-200 fs-12">30-min Extension Rate</p>
                        <b className="fs-14">{(item?.hourlyprice || 0).toLocaleString()} JPY</b>
                      </div>
                      <div className="col-12 col-md-2">
                        <p className="m-0 fw-700 text-grey-200 fs-12">Airport Transfer Package Flat Price</p>
                        <b className="fs-14">
                          {booking?.package?.vehicles
                            ?.find((v) => v?.vehicle === item?.vehicleTypeID?._id)
                            ?.vehicleCost?.toLocaleString() ?? "-"}{" "}
                          JPY
                        </b>
                      </div>
                    </div>

                    <div className="row align-items-start p-3">
                      <div className="col-12 col-md-2">
                        <p className="m-0 fw-700 text-grey-200 fs-12">Sub Total {index + 1}</p>
                        <b className="fs-14">{(item?.total || 0).toLocaleString()} JPY</b>
                      </div>
                      <div className="col-12 col-md-2">
                        <p className="m-0 fw-700 text-grey-200 fs-12">Charter Cost</p>
                        <b className="fs-14">{(item?.vehiclePrice || 0).toLocaleString()} JPY</b>
                      </div>
                      <div className="col-12 col-md-2">
                        <p className="m-0 fw-700 text-grey-200 fs-12">Toll Fee</p>
                        <b className="fs-14">
                          {(item?.tollFare || 0) === 0
                            ? "-"
                            : `${(item?.tollFare || 0).toLocaleString()} JPY`}
                        </b>
                      </div>
                      <div className="col-12 col-md-2">
                        <p className="m-0 fw-700 text-grey-200 fs-12">Late Night Fee</p>
                        <b className="fs-14">
                          {item?.nightPrice ? item?.nightPrice.toLocaleString() + " JPY" : "- JPY"}
                        </b>
                      </div>
                    </div>
                  </div>
                ))}

                <hr className="hr-E8E8E8 m-0" />
                <div className="row align-items-start gap-5 p-3">
                  <div className="col-auto">
                    <p className="m-0 fw-700 text-grey-200 fs-12">Total Cost</p>
                    <b className="fs-14">{(booking?.totalPrice || 0).toLocaleString()} JPY</b>
                  </div>
                  <div className="col-auto">
                    <p className="m-0 fw-700 text-grey-200 fs-12">Charter Cost</p>
                    <b className="fs-14">
                      {booking?.bookingInfo?.reduce((p, c) => p + (c?.vehiclePrice ?? 0), 0).toLocaleString() || "0"}{" "}
                      JPY
                    </b>
                  </div>
                  <div className="col-auto">
                    <p className="m-0 fw-700 text-grey-200 fs-12">Toll Fee</p>
                    <b className="fs-14">
                      {booking?.bookingInfo?.reduce((sum, item) => sum + (item?.tollFare || 0), 0) === 0
                        ? "-"
                        : `${booking?.bookingInfo
                          ?.reduce((sum, item) => sum + (item?.tollFare || 0), 0)
                          .toLocaleString() || "0"
                        } JPY`}
                    </b>
                  </div>
                  <div className="col-auto">
                    <p className="m-0 fw-700 text-grey-200 fs-12">Late Night Fee</p>
                    <b className="fs-14">
                      {booking?.bookingInfo?.reduce((sum, item) => sum + (item?.nightPrice ?? 0), 0) === 0
                        ? "-"
                        : `${booking?.bookingInfo
                          ?.reduce((sum, item) => sum + (item?.nightPrice ?? 0), 0)
                          .toLocaleString() || "0"
                        } JPY`}
                    </b>
                  </div>
                  <div className="col-auto">
                    <p className="m-0 fw-700 text-grey-200 fs-12">Meet & Assist Service</p>
                    <b className="fs-14">
                      {booking?.meetAssist?.reduce((sum, item) => sum + (item?.meetAssistPrice || 0), 0) === 0
                        ? "-"
                        : `${booking?.meetAssist
                          ?.reduce((sum, item) => sum + (item?.meetAssistPrice || 0), 0)
                          .toLocaleString() || "0"
                        } JPY`}
                    </b>
                  </div>
                  <div className="col-auto">
                    <p className="m-0 fw-700 text-grey-200 fs-12">Child Seat</p>
                    <b className="fs-14">
                      {booking?.bookingInfo?.reduce((sum, item) => sum + (item?.childSeatPrice || 0), 0) === 0
                        ? "-"
                        : `${booking?.bookingInfo
                          ?.reduce((sum, item) => sum + (item?.childSeatPrice || 0), 0)
                          .toLocaleString() || "0"
                        } JPY`}
                    </b>
                  </div>
                  <div className="col-auto">
                    <p className="m-0 fw-700 text-grey-200 fs-12">Long Distance Surcharge</p>
                    <b className="fs-14">
                      {booking?.bookingInfo
                        ?.reduce((p, c) => p + (c?.longDistanceSurcharge || 0), 0)
                        .toLocaleString() || "0"}{" "}
                      JPY
                    </b>
                  </div>
                </div>
              </div>

              {booking?.flywireChargeAdditionalFeeResponse && (
                <div className="border rounded mt-2">
                  <div className="row align-items-start p-3">
                    <div className="col-auto">
                      <p className="m-0 fw-700 text-grey-200 fs-12">Additional Fee</p>
                      <b className="fs-14">
                        {booking?.flywireChargeAdditionalFeeResponse?.amount?.toLocaleString() || "0"} JPY
                      </b>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <h5 className={`fw-700`}>Payment Detail</h5>
            {
              //new charge payment detail
              !newPaymentStyle ? (
                <div className="card mb-5">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-8">
                        <h6 className="card-title">
                          Charge Status -{" "}
                          <p
                            className={`card-text py-1 ${booking.paymentStatus === "CHARGE_PENDING" &&
                              !booking.flywireChargeReference
                              ? "badge bg-warning py-2"
                              : booking.paymentStatus === "CHARGED" &&
                                booking.flywireChargeReference
                                ? "badge bg-success py-2"
                                : ""
                              }`}
                          >
                            {booking.paymentStatus === "CHARGE_PENDING" &&
                              !booking.flywireChargeReference
                              ? "CHARGE_PENDING"
                              : booking.paymentStatus === "CHARGED" &&
                                booking.flywireChargeReference
                                ? "Success"
                                : ""}
                          </p>
                        </h6>
                        <div className="d-flex align-items-center">
                          <div className="col-6 mb-3 mt-3">
                            <p className="m-0">Flywire Charge Account : {chargeAccount}</p>
                          </div>
                        </div>
                        {booking.paymentMethodStatus === "CHANGED" && (
                          <div className="d-flex align-items-center">
                            <div className="col-6 mb-3 mt-3">
                              <p className="m-0">CC Info : CHANGED at {moment(booking.paymentMethodUpdatedAt).format(
                                "YYYY/MM/DD hh:mm A"
                              )}</p>
                            </div>
                          </div>
                        )}
                        <div className="d-flex align-items-center">
                          <div className="col-3">
                            <p className="m-0">Charge New Total Amount</p>
                          </div>
                          <div className="col-3">
                            <input
                              type="number"
                              class="form-control"
                              aria-describedby="helpId"
                              placeholder="Amount"
                              onChange={(e) =>
                                setNewTotalBooking(e.target.value)
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <button
                              disabled={
                                booking.isAbandoned ||
                                !booking.flywireToken ||
                                booking.bookingStatus !== "CONFIRMED"
                              }
                              className="btn btn-secondary ms-2"
                              onClick={() => {
                                handleOpenModalConfirmation(
                                  `Are you sure you want to charge this booking ${booking.paymentStatus === "CHARGE_PENDING"
                                    ? "with edited total booking cost"
                                    : (booking.paymentStatus === "CHARGED" || booking.paymentStatus === "CHARGE_INITIATED")
                                      ? "with added additional fee"
                                      : ""
                                  }?`,
                                  () =>
                                    chargeTokenHandler(
                                      booking._id,
                                      false,
                                      booking.paymentStatus ===
                                        "CHARGE_PENDING"
                                        ? "edited"
                                        : booking.paymentStatus === "CHARGED"
                                          ? "additional"
                                          : "",
                                      newTotalBooking
                                    )
                                );
                              }}
                            >
                              <i
                                className="fa fa-money fa-fw"
                                aria-hidden="true"
                              ></i>
                              &nbsp;
                              {booking.paymentStatus === "CHARGE_PENDING" && "Charge With Edited Total Booking Cost"}
                              {(booking.paymentStatus === "CHARGED" || booking.paymentStatus === "CHARGE_INITIATED") && "Add Additional Fee "}
                            </button>
                          </div>
                        </div>
                        <hr className="divider" />
                        {booking?.cartID && (
                          <div className="d-flex mt-3 gap-3">
                            {checkChargeLoading ? (
                              <>
                                <span
                                  class="spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                &nbsp; Checking charge options...
                              </>
                            ) : (
                              <>
                                {bookingsWithCartIdLength > 1 &&
                                  booking.paymentStatus !== "CHARGED" && (
                                    <>
                                      <button
                                        disabled={
                                          booking.isAbandoned ||
                                          !booking.flywireToken ||
                                          booking.bookingStatus !== "CONFIRMED" ||
                                          booking.paymentStatus !== "CHARGE_PENDING"
                                        }
                                        className="btn btn-secondary ms-1"
                                        onClick={() => {
                                          handleOpenModalConfirmation(
                                            "Are you sure want to charge the whole cart?",
                                            () =>
                                              chargeTokenHandler(
                                                booking.cartID,
                                                true,
                                                "",
                                                0
                                              )
                                          );
                                        }}
                                      >
                                        <i
                                          className="fa fa-money fa-fw"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;
                                        {booking.paymentStatus ===
                                          "CHARGE_PENDING" &&
                                          !booking.flywireChargeReference
                                          ? "Charge with the whole cart!"
                                          : ""}
                                      </button>
                                    </>
                                  )}
                                <button
                                  disabled={
                                    booking.isAbandoned ||
                                    !booking.flywireToken ||
                                    booking.bookingStatus !== "CONFIRMED" ||
                                    booking.paymentStatus !== "CHARGE_PENDING"
                                  }
                                  className="btn btn-secondary ms-1"
                                  onClick={() => {
                                    handleOpenModalConfirmation(
                                      "Are you sure want to charge this booking?",
                                      () =>
                                        chargeTokenHandler(
                                          booking._id,
                                          false,
                                          "",
                                          0
                                        )
                                    );
                                  }}
                                >
                                  <i
                                    className="fa fa-money fa-fw"
                                    aria-hidden="true"
                                  ></i>
                                  &nbsp;
                                  {
                                    booking.paymentStatus === "CHARGE_PENDING" && !booking.flywireChargeReference ? `Charge!`
                                      : booking.paymentStatus === "CHARGE_INITIATED" ? "Initiated by Flywire"
                                        : booking.paymentStatus === "CHARGED" && booking.flywireChargeReference ? "Successfuly Charged" : ""
                                  }
                                </button>

                                <button
                                  disabled={
                                    booking.isAbandoned ||
                                    booking.paymentStatus === "CHARGED"
                                  }
                                  // onClick={() => sendMailChangeCCInfoHandler(booking.cartID)}
                                  onClick={handleOpenModalConfirmationUpdateCCInfo}
                                  className="btn btn-secondary ms-1"
                                >
                                  <i
                                    className="fa fa-envelope-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Send Email to Change CC Info
                                </button>
                              </>
                            )}

                            {booking.paymentStatus === "CHARGE_PENDING" &&
                              booking.requestChangeBookingData &&
                              booking.requestChangeBookingData
                                .currentStatus === "CHARGE_PENDING" && (
                                <button
                                  disabled={
                                    booking.isAbandoned ||
                                    !booking.flywireToken ||
                                    booking.bookingStatus !== "CONFIRMED" ||
                                    booking.paymentStatus !== "CHARGE_PENDING"
                                  }
                                  className="btn btn-secondary ms-1"
                                  onClick={() => {
                                    chargeTokenWithRequestedChangesHandler(
                                      booking._id
                                    );
                                  }}
                                >
                                  <i
                                    className="fa fa-checklist"
                                    aria-hidden="true"
                                  ></i>
                                  &nbsp; Charge Booking and Approve Requested
                                  Changes!
                                </button>
                              )}
                          </div>
                        )}
                      </div>
                      {booking.flywireChargeResponse && (
                        <div className="col-md-4">
                          <h6 className="card-title">Reference</h6>
                          <p className="card-text py-1 py-1 mb-1 text-success">
                            {booking.flywireChargeResponse.reference}
                          </p>

                          {booking.flywireChargeResponse && (
                            <div className="row">
                              <div className="col">
                                <h6 className="card-title">Payer Amount</h6>
                                <p className="card-text py-1 py-1 mb-1 text-success">
                                  {`${booking.flywireChargeResponse.payerAmount
                                    } ${booking.flywireChargeWithCart
                                      ? "- With the whole cart"
                                      : ""
                                    }`}
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">
                                  Payer Amount Currency
                                </h6>
                                <p className="card-text py-1 py-1 mb-1 text-success">
                                  {
                                    booking.flywireChargeResponse
                                      .payerAmountCurrency
                                  }
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">Amount</h6>
                                <p className="card-text py-1 py-1 mb-1 text-success">
                                  {`${booking.flywireChargeResponse.amount} ${booking.flywireChargeWithCart
                                    ? "- With the whole cart"
                                    : ""
                                    }`}
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">
                                  Amount Currency
                                </h6>
                                <p className="card-text py-1 py-1 mb-1 text-success">
                                  {
                                    booking.flywireChargeResponse
                                      .amountCurrency
                                  }
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {booking.flywireChargeAdditionalFeeResponse && (
                        <div className="col-md-4">
                          <h6 className="card-title">
                            Reference Additional Fee
                          </h6>
                          <p className="card-text py-1 py-1 mb-1 text-success">
                            {
                              booking.flywireChargeAdditionalFeeResponse
                                .reference
                            }
                          </p>

                          {booking.flywireChargeAdditionalFeeResponse && (
                            <div className="row">
                              <div className="col">
                                <h6 className="card-title">
                                  Payer Amount Additional Fee
                                </h6>
                                <p className="card-text py-1 py-1 mb-1 text-success">
                                  {`${booking.flywireChargeAdditionalFeeResponse
                                    .payerAmount
                                    } ${booking.flywireChargeWithCart
                                      ? "- With the whole cart"
                                      : ""
                                    }`}
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">
                                  Payer Amount Currency Additional Fee
                                </h6>
                                <p className="card-text py-1 py-1 mb-1 text-success">
                                  {
                                    booking.flywireChargeAdditionalFeeResponse
                                      .payerAmountCurrency
                                  }
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">
                                  Amount Additional Fee
                                </h6>
                                <p className="card-text py-1 py-1 mb-1 text-success">
                                  {`${booking.flywireChargeAdditionalFeeResponse
                                    .amount
                                    } ${booking.flywireChargeWithCart
                                      ? "- With the whole cart"
                                      : ""
                                    }`}
                                </p>
                              </div>
                              <div className="col">
                                <h6 className="card-title">
                                  Amount Currency Additional Fee
                                </h6>
                                <p className="card-text py-1 py-1 mb-1 text-success">
                                  {
                                    booking.flywireChargeAdditionalFeeResponse
                                      .amountCurrency
                                  }
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="border rounded mb-5">
                  {booking?.cartID && (
                    <div className="d-flex p-3 gap-5">
                      {checkChargeLoading ? (
                        <>
                          <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                          &nbsp; Checking charge options...
                        </>
                      ) : (
                        <>
                          {/* Charge Whole Cart Button */}
                          {bookingsWithCartIdLength > 1 && booking?.paymentStatus !== "CHARGED" && (
                            <button
                              className={`btn ms-1 rounded ${booking?.isAbandoned ||
                                !booking?.flywireToken ||
                                booking?.bookingStatus !== "CONFIRMED" ||
                                booking?.paymentStatus !== "CHARGE_PENDING"
                                ? "btn-light"
                                : "btn-primary"
                                }`}
                              disabled={
                                booking?.isAbandoned ||
                                !booking?.flywireToken ||
                                booking?.bookingStatus !== "CONFIRMED" ||
                                booking?.paymentStatus !== "CHARGE_PENDING"
                              }
                              onClick={() =>
                                handleOpenModalConfirmation("Are you sure want to charge the whole cart?", () =>
                                  chargeTokenHandler(booking?.cartID, true, "", 0),
                                )
                              }
                            >
                              {booking?.paymentStatus === "CHARGE_PENDING" && !booking?.flywireChargeReference
                                ? "Charge with the whole cart!"
                                : ""}
                            </button>
                          )}

                          {/* Charge Single Booking Button */}
                          <button
                            className={`btn ms-1 rounded ${booking?.isAbandoned ||
                              !booking?.flywireToken ||
                              booking?.bookingStatus !== "CONFIRMED" ||
                              booking?.paymentStatus !== "CHARGE_PENDING"
                              ? "btn-light"
                              : "btn-primary"
                              }`}
                            disabled={
                              booking?.isAbandoned ||
                              !booking?.flywireToken ||
                              booking?.bookingStatus !== "CONFIRMED" ||
                              booking?.paymentStatus !== "CHARGE_PENDING"
                            }
                            onClick={() =>
                              handleOpenModalConfirmation("Are you sure want to charge this booking?", () =>
                                chargeTokenHandler(booking?._id, false, "", 0),
                              )
                            }
                          >
                            {booking?.paymentStatus === "CHARGE_PENDING" && !booking?.flywireChargeReference
                              ? "Charge"
                              : booking?.paymentStatus === "CHARGE_INITIATED"
                                ? "Initiated by Flywire"
                                : booking?.paymentStatus === "CHARGED" && booking?.flywireChargeReference
                                  ? "Charged"
                                  : ""}
                          </button>
                        </>
                      )}

                      {booking?.paymentStatus === "CHARGE_PENDING" &&
                        booking?.requestChangeBookingData &&
                        booking?.requestChangeBookingData?.currentStatus === "CHARGE_PENDING" && (
                          <button
                            disabled={
                              booking?.isAbandoned ||
                              !booking?.flywireToken ||
                              booking?.bookingStatus !== "CONFIRMED" ||
                              booking?.paymentStatus !== "CHARGE_PENDING"
                            }
                            className={`btn ms-1 rounded ${booking?.isAbandoned ||
                              !booking?.flywireToken ||
                              booking?.bookingStatus !== "CONFIRMED" ||
                              booking?.paymentStatus !== "CHARGE_PENDING"
                              ? "btn-light"
                              : "btn-primary"
                              }`}
                            onClick={() => {
                              chargeTokenWithRequestedChangesHandler(booking?._id)
                            }}
                          >
                            Charge Booking and Approve Requested Changes!
                          </button>
                        )}

                      <div className="col-auto">
                        <p className="m-0 fw-700 text-grey-200 fs-12 ">Flywire Charge Account</p>
                        <b className="fs-14">{chargeAccount}</b>
                      </div>
                      <div className="col-auto">
                        <p className="m-0 fw-700 text-grey-200 fs-12 ">Status</p>
                        <b className="fs-14">{booking?.paymentStatus || "-"}</b>
                      </div>
                      <div className="col">
                        <p className="m-0 fw-700 text-grey-200 fs-12">Flywire Token</p>
                        <b className="fs-14 text-wrap text-break">{booking?.flywireToken || "-"}</b>
                      </div>
                    </div>
                  )}

                  <hr className="hr-E8E8E8 m-0" />
                  <div className="row p-3">
                    <div className="col-3">
                      <b className="fs-14">Charge Additional Fee</b>
                      <p className="m-0 fs-12 ">After the Transfer</p>
                    </div>
                    <div className="col-9">
                      <div className="d-flex gap-3">
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="toll_fee_new">Toll Fee (New)</label>
                            <br />
                            <input
                              type="number"
                              className="border rounded p-2 mt-2 w-100"
                              name="toll_fee_new"
                              id="toll_fee_new"
                              placeholder="Amount"
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="charter_cost_fee_new">Charter Cost (NEW)</label>
                            <br />
                            <input
                              type="number"
                              className="border rounded p-2 mt-2 w-100"
                              name="charter_cost_fee_new"
                              id="charter_cost_fee_new"
                              placeholder="Amount"
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="late_night_fee_new">Late Night Fee (NEW)</label>
                            <br />
                            <input
                              type="number"
                              className="border rounded p-2 mt-2 w-100"
                              name="late_night_fee_new"
                              id="late_night_fee_new"
                              placeholder="Amount"
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="total_fee_new">Total (NEW)</label>
                            <br />
                            <input
                              type="number"
                              className="border rounded p-2 mt-2 w-100"
                              name="total_fee_new"
                              id="total_fee_new"
                              placeholder="Amount"
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="payment_received">Payment Received</label>
                            <br />
                            <input
                              type="text"
                              className="border rounded p-2 mt-2 w-100"
                              name="payment_received"
                              id="payment_received"
                              placeholder="Amount"
                              disabled={true}
                              value={
                                booking?.flywireChargeResponse?.amount
                                  ? booking.flywireChargeResponse.amount.toLocaleString() + " JPY"
                                  : "-"
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex gap-3">
                        <div className="mb-3 col">
                          <label htmlFor="toll_fee_new">Additional Fee</label>
                          <br />
                          <input
                            type="number"
                            className="border rounded p-2 mt-2 w-100"
                            name="toll_fee_new"
                            id="toll_fee_new"
                            placeholder="Amount"
                            onChange={(e) => setNewTotalBooking(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="d-flex gap-3">
                        <div className="mb-3 col">
                          <label htmlFor="toll_fee_new">Detailed Itinerary</label>
                          <br />
                          <textarea name="" id="" className="border rounded p-2 mt-2 w-100"></textarea>
                        </div>
                      </div>

                      <button
                        disabled={booking?.isAbandoned || !booking?.flywireToken || booking?.bookingStatus !== "CONFIRMED"}
                        className="btn rounded border-gold bg-gold-light-100 "
                        onClick={() => {
                          handleOpenModalConfirmation(
                            `Are you sure you want to charge this booking ${booking?.paymentStatus === "CHARGE_PENDING"
                              ? "with edited total booking cost"
                              : booking?.paymentStatus === "CHARGED" || booking?.paymentStatus === "CHARGE_INITIATED"
                                ? "with added additional fee"
                                : ""
                            }?`,
                            () =>
                              chargeTokenHandler(
                                booking?._id,
                                false,
                                booking?.paymentStatus === "CHARGE_PENDING"
                                  ? "edited"
                                  : booking?.paymentStatus === "CHARGED"
                                    ? "additional"
                                    : "",
                                newTotalBooking,
                              ),
                          )
                        }}
                      >
                        &nbsp;
                        {booking?.paymentStatus === "CHARGE_PENDING" && "Charge With Edited Total Booking Cost"}
                        {(booking?.paymentStatus === "CHARGED" || booking?.paymentStatus === "CHARGE_INITIATED") &&
                          "Add Additional Fee "}
                      </button>
                    </div>
                  </div>
                </div>
              )
            }

            {booking?.requestChangeBookingData &&
              (booking.requestChangeBookingData.requestType === "reschedule" ? (
                <div className="card mb-3">
                  <div className="card-header">Request Change Booking Information</div>
                  <div className="card-body text-dark">
                    <div className="row">
                      <div className="col mb-2">
                        <h6 className="card-title">Current Request Status</h6>
                        <p
                          className={`card-text py-1 ${booking.requestChangeBookingData.approvalStatus === 0
                            ? "badge bg-warning py-2"
                            : booking.requestChangeBookingData.approvalStatus === 1
                              ? "badge bg-success py-2"
                              : "badge bg-danger py-2"
                            }`}
                        >
                          {booking.requestChangeBookingData.approvalStatus === 0
                            ? "Waiting for Approval"
                            : booking.requestChangeBookingData.approvalStatus === 1
                              ? "Approved"
                              : "Rejected"}
                        </p>
                        <h6 className="card-title">Pickup Time</h6>
                        <p className="card-text py-1 mb-2">
                          {booking.requestChangeBookingData.travelDateRequest
                            ? moment(booking.requestChangeBookingData.travelDateRequest).format("YYYY/MM/DD hh:mm A")
                            : "-"}
                        </p>
                        <h6 className="card-title">Dropoff Time</h6>
                        <p className="card-text py-1 mb-2">
                          {booking.requestChangeBookingData.dropoffTimeRequest
                            ? moment(booking.requestChangeBookingData.dropoffTimeRequest).format("YYYY/MM/DD hh:mm A")
                            : "-"}
                        </p>
                        <button
                          onClick={() =>
                            history.push(
                              `/admin/cancel-reschedule-request/manage/${booking.requestChangeBookingData._id}`,
                            )
                          }
                          className="btn btn-secondary ms-1"
                        >
                          <i className="fa fa-link" aria-hidden="true"></i> Go to Change Booking Request Detail
                        </button>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <h6 className="card-title mt-4">Calculated dispatch from Garage</h6>
                            <p className="card-text py-1">
                              {booking.requestChangeBookingData.startFromGarage
                                ? moment(booking.requestChangeBookingData.startFromGarage).format("YYYY/MM/DD hh:mm A")
                                : "-"}
                            </p>
                            <h6 className="card-title">Calculated arrival at Garage</h6>
                            <p className="card-text py-1">
                              {booking.requestChangeBookingData.arrivalAtGarage
                                ? moment(booking.requestChangeBookingData.arrivalAtGarage).format("YYYY/MM/DD hh:mm A")
                                : "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {booking.requestChangeBookingData.pickupLocationRequest && (
                      <>
                        <h6 className="card-title">Pickup Location</h6>
                        <p className="card-text py-1">
                          {booking.requestChangeBookingData.pickupLocationRequest.formatted_address}
                        </p>
                      </>
                    )}

                    <h6 className="card-title">Destinations</h6>
                    {booking.requestChangeBookingData.destinationsRequest ? (
                      booking.requestChangeBookingData.destinationsRequest.map((d) => (
                        <>
                          <p key={d?._id || Math.random()} className="card-text py-1">
                            {d?.label || ""}
                          </p>
                          <br />
                        </>
                      ))
                    ) : (
                      <h4>No destinations found...</h4>
                    )}
                  </div>
                </div>
              ) : (
                <div className="card mb-3">
                  <div className="card-header">Cancellation Booking Request</div>
                  <div className="card-body text-dark">
                    <div className="row">
                      <div className="col mb-2">
                        <h6 className="card-title">Current Request Status</h6>
                        <p
                          className={`card-text py-1 ${booking.requestChangeBookingData.approvalStatus === 0
                            ? "badge bg-warning py-2"
                            : booking.requestChangeBookingData.approvalStatus === 1
                              ? "badge bg-success py-2"
                              : "badge bg-danger py-2"
                            }`}
                        >
                          {booking.requestChangeBookingData.approvalStatus === 0
                            ? "Waiting for Approval"
                            : booking.requestChangeBookingData.approvalStatus === 1
                              ? "Approved"
                              : "Rejected"}
                        </p>
                        <h6 className="card-title mt-4">Percentage Cancellation Fee (%)</h6>
                        <p className="card-text py-1">{booking.requestChangeBookingData.percentCancelFee || 0}%</p>
                        <h6 className="card-title">Refund Percentage (%)</h6>
                        <p className="card-text py-1">{booking.requestChangeBookingData.percentRefund || 0}%</p>
                        <button
                          onClick={() =>
                            history.push(
                              `/admin/cancel-reschedule-request/manage/${booking.requestChangeBookingData._id}`,
                            )
                          }
                          className="btn btn-secondary ms-1"
                        >
                          <i className="fa fa-link" aria-hidden="true"></i> Go to Cancellation Request Detail
                        </button>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <h6 className="card-title mt-4">Cancellation Request Created</h6>
                            <p className="card-text py-1">
                              {booking.requestChangeBookingData.requestCreatedAt
                                ? moment(booking.requestChangeBookingData.requestCreatedAt).format("YYYY/MM/DD hh:mm A")
                                : "-"}
                            </p>
                            <h6 className="card-title mt-4">Cancellation Fee</h6>
                            <p className="card-text py-1">
                              {(booking.requestChangeBookingData.partialCancellationFee || 0).toLocaleString()}
                              JPY
                            </p>
                            <h6 className="card-title mt-4">Total Refund Amount</h6>
                            <p className="card-text py-1">
                              {(booking.requestChangeBookingData.totalRefundAmount || 0).toLocaleString()}
                              JPY
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            <h5 className={`fw-700`}>Assigned Vehicle</h5>
            {booking?.bookingInfo?.map((item, index) => {
              return (
                <div key={index}>
                  <div className="border rounded">
                    <div className="row p-3 align-items-center gap-5 p-3">
                      <div className="col-auto">
                        <b className="fs-14">Vehicle {index + 1}</b>
                      </div>
                      <div className="col-3">
                        <div className="d-flex justify-content-start">
                          <div className="col">
                            <Select
                              isDisabled={isDisabled}
                              className="basic-multi-select"
                              classNamePrefix="select pick-up location"
                              value={selectedOption[index]}
                              onChange={(e) => {
                                const newOptions = [...selectedOption]
                                newOptions[index] = e
                                setSelectedOption(newOptions)
                              }}
                              options={activeVehicles}
                            />
                          </div>
                          <button
                            disabled={isDisabled}
                            type="submit"
                            className="btn rounded border-gold ms-1"
                            onClick={(e) => {
                              setSelectedOption((selectedOption) => ({
                                ...selectedOption,
                                [index]: e,
                              }))
                            }}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                      <div className="col-auto">
                        <p className="m-0 fw-700 text-grey-200 fs-12 ">Vehicle Type</p>
                        <b className="fs-14">{item?.vehicleTypeName || "-"}</b>
                      </div>
                      <div className="col-auto">
                        <p className="m-0 fw-700 text-grey-200 fs-12 ">Vehicle Name</p>
                        <b className="fs-14">{item?.vehicle?.vehiclename || "-"}</b>
                      </div>
                      <div className="col-auto">
                        <p className="m-0 fw-700 text-grey-200 fs-12 ">Registration Number</p>
                        <b className="fs-14"> {item?.vehicle?.registration || "-"}</b>
                      </div>
                    </div>

                    <hr className="hr-E8E8E8 m-0" />
                    <div className="row p-3 align-items-center gap-5 p-3">
                      <div className="col-auto">
                        <b className="fs-14">Driver {index + 1}</b>
                      </div>
                      <div className="col-3">
                        <div className="d-flex justify-content-start">
                          <div className="col">
                            <Select
                              className="basic-multi-select"
                              classNamePrefix="select pick-up location"
                              value={driverSelectedOption[index]}
                              onChange={(e) => {
                                const newDriverOptions = [...driverSelectedOption]
                                newDriverOptions[index] = e
                                setDriverSelectedOption(newDriverOptions)
                              }}
                              options={activeDrivers}
                            />
                          </div>
                          <button
                            disabled={booking?.isAbandoned}
                            type="submit"
                            className="btn rounded border-gold ms-1"
                            onClick={() => updateBookingDriver(index)}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                      <div className="col-auto">
                        <p className="m-0 fw-700 text-grey-200 fs-12 ">Driver's Name</p>
                        {item?.driver?.name !== undefined ? (
                          <b className="fs-14">{item?.driver?.name}</b>
                        ) : (
                          <span className="badge bg-info">Driver not assigned.</span>
                        )}
                      </div>
                      <div className="col-auto">
                        <p className="m-0 fw-700 text-grey-200 fs-12 ">Driver's Phone No.</p>
                        <b className="fs-14">{item?.driver?.phone ?? "-"}</b>
                      </div>
                      <div className="col-auto">
                        <p className="m-0 fw-700 text-grey-200 fs-12 ">Driver's Email</p>
                        <b className="fs-14"> {item?.driver?.email ?? "-"}</b>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}

        {loading === true && <>Get bookings...</>}
      </main>
    </Layout>
  )
}

export default ManageBookingV2

