
import customAxios from "../../utils/customAxios";
import axios from "axios";
import { setAlert } from "../alert";


export const flywireSuccessPayment = async (flywireResponse) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/success-payment",
            flywireResponse,
            config
        );
        return res;
    } catch (err) {
        return [];
    }
};

export const flywireUpdatePayment = async (flywireResponse) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/update-payment",
            flywireResponse,
            config
        );
        return res;
    } catch (err) {
        return [];
    }
};

export const flywireUpdatePaymentAuthUser = async (token, selectedPaymentId) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/update-payment-auth-user",
            {
                token,
                selectedPaymentId
            },
            config
        );
        return res;
    } catch (err) {
        return [];
    }
};

export const flywireAddPayment = async (flywireResponse) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/add-payment",
            flywireResponse,
            config
        );
        return res;
    } catch (err) {
        return [];
    }
};

export const flywireAddPaymentUser = async (flywireResponse) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/add-payment-new",
            flywireResponse,
            config
        );
        return res;
    } catch (err) {
        return [];
    }
};

export const flywireAddPaymentMethod = async (flywireResponse) => {
    try {
        // Mengambil token dari local storage
        const token = localStorage.getItem('token');

        // Pastikan token ada sebelum melakukan fetch
        if (token) {
            const response = await axios.post('/api/flywire/add-payment-method', {
                flywireResponse,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token,
                },
            });


            console.log("response-000000000")
            console.log(response)
            console.log(response.data)
            return response.data;
        }
    } catch (err) {
        console.error("Error in flywireAddPaymentMethod:", err.response ? err.response.data : {});
        return err.response ? err.response.data : {} ;
    }
};

export const setDefaultPayment = async (token, paymentId) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/set-default-payment",
            {
                token: token,
                paymentId: paymentId
            },
            config
        );
        return res;
    } catch (err) {
        return [];
    }
};

export const deletePayment = async (token, paymentId) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/delete-payment",
            {
                token: token,
                paymentId: paymentId
            },
            config
        );
        return res;
    } catch (err) {
        return [];
    }
};

export const deletePaymentMethod = async (individualDetailId, paymentId) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/delete-payment-method",
            {
                individualDetailId: individualDetailId,
                paymentMethodId: paymentId
            },
            config
        );
        return res;
    } catch (err) {
        return [];
    }
};

// charge token
export const chargeToken = async (id) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/charge-token",
            { bookingId: id },
            config
        );

        return res;
    } catch (err) {
        return err;
    }
};

// charge token eddited fee
export const chargeTokenEditedFee = async (id, editedFee) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/charge-token-with-edited-fee",
            {
                bookingId: id,
                editedFee: editedFee
            },
            config
        );

        return res;
    } catch (err) {
        return err;
    }
};

// charge token additional fee
export const chargeTokenAdditionalFee = async (id, additionalFee) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/charge-token-additional-fee",
            {
                bookingId: id,
                additionalFee: additionalFee
            },
            config
        );

        return res;
    } catch (err) {
        return err;
    }
};

export const chargeTokenByCartId = async (id) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/charge-token-by-cart-id",
            { cartID: id },
            config
        );

        return res;
    } catch (err) {
        return err;
    }
};

// charge token with requested changes
export const chargeTokenWithRequestedChanges = async (id) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/charge-token-with-changes",
            { bookingId: id },
            config
        );

        return res;
    } catch (err) {
        return err;
    }
};

export const chargeTokenReschedule = async (id) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/flywire/charge-token-reschedule",
            { requestId: id },
            config
        );

        return res;
    } catch (err) {
        return err;
    }
};

