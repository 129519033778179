export const vehicleTypeOptions = [
  { value: "v1", label: "Alphard" },
  { value: "v2", label: "GC" },
  { value: "v3", label: "LC" },
  { value: "v4", label: "Granace" },
];

export const baggageOptions = [
  { value: "l4", label: "Check-in size" },
  { value: "l5", label: "Cabin size" },
];
export const paxOptions = [
  { value: "p1", label: "Adult" },
  { value: "p2", label: "Child" },
  { value: "p3", label: "Infant" },
];
export const options = [
  { value: "colombo1", label: "Park road 1, Pannipitiya" },
  { value: "colombo2", label: "Park road 2, Pannipitiya" },
  { value: "colombo3", label: "Park road 3, Pannipitiya" },
  { value: "colombo4", label: "Park road 4, Pannipitiya" },
  { value: "colombo5", label: "Park road 5, Pannipitiya" },
];
export const vehicleTypeImageOptions = [
  { value: "v1", label: "Alphard" },
  { value: "v2", label: "Grand-Cabin" },
  { value: "v3", label: "Luxury-Cabin" },
  { value: "v4", label: "Granace" },
  { value: "v5", label: "Benz V Class" },
];

export const bookingStatusOptions = [
  { value: "s1", label: "INQUIRY" },
  { value: "s2", label: "PAYMENT_PENDING" },
  { value: "s3", label: "CONFIRMED" },
  { value: "s4", label: "CANCELLED" },
];

export const newBookingStatusOptions = [
  { value: "CONFIRMED", label: "Confirmed" },
  { value: "ADDED_TO_CART", label: "Added To Cart" },
  { value: "READY_TO_CHECKOUT", label: "Ready To Checkout" },
  { value: "CANCELLED", label: "Canceled" },
];

export const newPaymentStatusOptions = [
  { value: "WAITING_FOR_PAYMENT", label: "Waiting for payment" },
  { value: "CHARGE_PENDING", label: "Ready To Charge" },
  { value: "CHARGED", label: "Charged" },
  { value: "CHARGE_INITIATED", label: "Charge is being Initiated" },
  { value: "CHARGE_FAILED", label: "Charge Failed" },
];

export const newBookingTypeOptions = [
  { value: "specifiedDestination", label: "Specified Destination" },
  { value: "byTheHour", label: "By The Hour" },
];

export const bookingTypeOptions = [
  // { value: "b1", label: "Admin" },
  { value: "b2", label: "Online" },
];

export const iconTypes = [
  { value: "i1", label: "Passengers icon" },
  { value: "i2", label: "Suitcases icon" },
  { value: "i3", label: "Mobile device charger icon" },
  { value: "i4", label: "television" },
  { value: "i4", label: "In-car wifi" },
  { value: "i4", label: "DVD-player icon" },
];

export const travelAreaOptions = [
  {
    value: "withinCity",
    label: "ByTheHour.TravelArea.WithinCity",
  },
  {
    value: "neighboringPerfecture",
    label: "ByTheHour.TravelArea.NeighboringPerfecture",
  },
  {
    value: "undecided",
    label: "ByTheHour.TravelArea.Undecided",
  },
];

export const travelAreaOptionsEnglish = [
  {
    value: "withinCity",
    label: "Within the city/prefecture",
  },
  {
    value: "neighboringPerfecture",
    label: "To the neighboring city/prefecture",
  },
  {
    value: "undecided",
    label: "Undecided",
  },
];

// 1 hour	3600 seconds
// 1 day	86400 seconds
// 1 week	604800 seconds
// 1 month (30.44 days) 	2629743 seconds
// 1 year (365.24 days) 	 31556926 seconds
