import { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import PageNotFound from "../../404page/PageNotFound";
import {
  getDetails,
  getBookingType,
  paymentEligibility,
  finishPayment,
} from "../../../actions/admin/quotation";
import {
  flywireAddPaymentMethod,
} from "../../../actions/admin/flywire";
import { getLocationByAddress } from "../../../actions/googleAPI";
import moment from "moment";
import logo from "../../../images/tlf-logo-cropped.png";
import logoMobile from "../../../images/tlf-logo-cropped-mobile.png";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import LanguageCard from "../../layout-new-theme/LanguageCard";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import CustomerLogin from "../../auth/CustomerLogin";
import CustomerRegister from "../../auth/CustomerRegister";
import arrow_right_white_icon from "../../../icons/arrow_right_white_icon.svg";
import print_white_icon from "../../../icons/print_white_icon.svg";
import tlf_logo_quotation from "../../../images/tlf-logo-quotation.png";
import collapse_up_icon from "../../../icons/collapse_up_icon.svg";
import collapse_down_icon from "../../../icons/collapse_down_icon.svg";
import QuotationModal from "./QuotationModal";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { setLanguage } from "../../../actions/admin/settings";
import TNCTables from "./TNCTables";
import PaymentMethodSelectionModal from "./PaymentMethodSelectionModal";
import AlertModal from "../../AlertModal";

export const QuotationLinkPage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const settings = useSelector((state) => state.setting);

  const [showTNCModal, setShowTNCModal] = useState(false);

  const [showTNCTables, setShowTNCTables] = useState(false);

  const handleCloseTNCModal = () => setShowTNCModal(false);

  const handleOpenTNCModal = () => setShowTNCModal(true);



  const history = useHistory();
  const { lang } = settings;
  if (lang === "EN") {
    moment.locale("en");
  } else {
    moment.locale("ja");
  }

  const langChange = (lng) => {
    if (lng === null) return;

    i18n.changeLanguage(lng.toLowerCase());
    localStorage.setItem("lang", lng);
    dispatch(setLanguage(lng.toUpperCase()));
  };

  useEffect(() => {
    if (history.location.search.includes("lang")) {
      const lng = history.location.search.split("=");
      langChange(lng[1]);
    } else {
      langChange(lang);
    }
  }, []);

  const { quotationId } = useParams();

  const [quotationDetail, setQuotationDetail] = useState(null);

  const [error, setError] = useState(false);

  const [isShow, setIsShow] = useState(false);

  const [showModal, setShowModal] = useState(false);


  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);


  const handleSelectedPaymentMethod = (id) => setSelectedPaymentMethod(id);


  const [showModaSelectPaymentMethod, setShowModaSelectPaymentMethod] = useState(false);

  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertText, setmodalAlertText] = useState("");

  const handleCloseModalAlert = () => {
    setShowModalAlert(false);
  };

  const handleModalSelectPaymentMethod = (value) => setShowModaSelectPaymentMethod(value);

  useEffect(() => {
    if (quotationId) {
      handleFetchDetailQuotation(quotationId);
    } else {
      setError(true);
    }
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedJwt = jwtDecode(token);
      if (decodedJwt.user.id._id !== quotationDetail.user._id) {
        setShowModal(true);
      } else {
        setIsShow(true);
      }
    } else {
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (quotationDetail !== null) {
      if (quotationDetail.user.signUpStatus !== "QUOTATION_GUEST") {
        checkToken();
      } else {
        setIsShow(true);
      }
    }
  }, [quotationDetail]);

  const handleFetchDetailQuotation = async (quotationId) => {
    try {
      var res = await getDetails(quotationId);
      if (res.bookings.length > 0) {
        setQuotationDetail(res);
        handleSelectedPaymentMethod(res.user.individualDetail.paymentMethod.find(method => method.isActive === true)._id);

      } else {
        // setError(true);
      }
    } catch (error) {
      // setError(true);
    }
  };

  const callbackFlywireAuthenticatedUser = async (result, ref) => {
    result.ref = ref;
    if (result.status === "success") {
      const resFinishPayment = await finishPayment({
        ...result,
        id: quotationDetail._id,
        flywireStatus: "UNREGISTERED",
        paymentMethodId: selectedPaymentMethod,
      });

      if (
        resFinishPayment.code === 200 &&
        resFinishPayment.status === "SUCCESS"
      ) {
        history.push(`/PaymentSuccess/${resFinishPayment.data.quotationId}`);
      }
    }
  };

  const extractFirstAndLastName = async (fullName) => {
    // Split the full name into an array of strings
    const nameParts = fullName.trim().split(" ");

    // Extract the first name (first element of the array)
    const firstName = nameParts[0];

    // Extract the last name (last element of the array, if exists)
    const lastName =
      nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";

    // Return an object containing the first name and last name
    return {
      firstName: firstName,
      lastName: lastName ? lastName : firstName,
    };
  };

  const payButtonAuthenticatedUser = async () => {
    var resFinishPayment = await finishPayment({
      id: quotationId,
      flywireStatus: "REGISTERED",
      paymentMethodId: selectedPaymentMethod
    });

    if (resFinishPayment.status === "SUCCESS") {
      history.push(`/PaymentSuccess/${resFinishPayment.data.quotationId}`);
    } else {
      console.log("resFinishPayment.status = FAILED");
    }

  }

  const payButtonHandle = async () => {
    handleCloseTNCModal();

    var res = await paymentEligibility(quotationId);

    if (res.status === "ELIGIBLE") {
      if (res.flywireStatus === "REGISTERED") {
        handleModalSelectPaymentMethod(true);

      } else {
        const zipCodeCheck = await getLocationByAddress(
          quotationDetail?.user.individualDetail.billingInformation.postCode,
          quotationDetail?.user.individualDetail.billingInformation.country_code
        );

        let city;
        if (
          zipCodeCheck.address_components.filter(
            (item) =>
              item.types.includes("administrative_area_level_2") ||
              item.types.includes("locality")
          ).length !== 0
        ) {
          city = zipCodeCheck.address_components.filter(
            (item) =>
              item.types.includes("administrative_area_level_2") ||
              item.types.includes("locality")
          )[0].long_name;
        } else {
          city =
            zipCodeCheck.address_components[
              zipCodeCheck.address_components.length - 2
            ].long_name;
        }

        if (zipCodeCheck) {
          const { firstName, lastName } = await extractFirstAndLastName(
            quotationDetail?.user.name
          );
          const config = {
            env: process.env.REACT_APP_FLYWIRE_ENV,
            recipientCode: process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,

            // >0 for Seed Payment and Optional Tokenization; 0 for Zero-Value Tokenization;
            amount: 0,
            //Mandatory tokenization before payment - "tokenization" or "optional_tokenization";
            recurringType: "tokenization",
            recurringTypeRecipientCodes: [
              process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,
              process.env.REACT_APP_FLYWIRE_JPT_RECIPIENT_CODE,
            ],

            // Specify the returnUrl or an onCompleteCallback handler
            // returnUrl: process.env.REACT_APP_URL + "/PaymentSuccess/"+ cart_id,

            // Other checkout parameters (e.g. pass payer info or set requestPayerInfo to true)
            firstName: firstName,
            lastName: lastName,
            email: quotationDetail?.user.email,
            phone:
              quotationDetail?.user.individualDetail.billingInformation
                .prefix_number +
              " " +
              quotationDetail?.user.individualDetail.billingInformation
                .phoneNumber,
            address:
              quotationDetail?.user.individualDetail.billingInformation.address,
            city: city,
            zip: quotationDetail?.user.individualDetail.postCode,
            country:
              quotationDetail?.user.individualDetail.billingInformation
                .country_code,

            recipientFields: {
              booking_reference: `QT-${quotationDetail._id}`,
              additional_information: null,
            },

            requestPayerInfo: false,
            requestRecipientInfo: false,

            // Control which tokenization options are available
            paymentOptionsConfig: {
              filters: {
                type: ["credit_card"],
              },
            },
            onCompleteCallback: function (args) {
              callbackFlywireAuthenticatedUser(
                args,
                `QT-${quotationDetail._id}`
              );
            },
            onInvalidInput: function (errors) {
              errors.forEach(function (error) {
                console.log(error.msg);
              });
            },
          };

          const modal = window.FlywirePayment.initiate(config);
          modal.render();
        } else {
          console.log("INVALID ZIP CODE");
        }
      }
    } else {
      console.log("NOT ELIGIBLE");
    }
  };

  const [openItems, setOpenItems] = useState(["00"]); // State to track open items

  const toggleOpen = (index) => {
    setOpenItems((prevOpenItems) => {
      if (prevOpenItems.includes(index)) {
        return prevOpenItems.filter((itemIndex) => itemIndex !== index); // Close the item
      } else {
        return [...prevOpenItems, index]; // Open the item
      }
    });
  };

  const getPhoneNumber = (item) => {
    if (item.role === "individual") {
      return `(${item.individualDetail.billingInformation.prefix_number ?? "-"
        })${item.individualDetail.billingInformation.phoneNumber}`;
    }
    if (item.role === "business") {
      return `(${item.businessDetail.billingInformation.prefix_number ?? "-"})${item.businessDetail.billingInformation.phoneNumber
        }`;
    }
    return "";
  };

  const handlePrint = async () => {
    var data = [];
    for (let index = 0; index < quotationDetail?.bookings.length; index++) {
      for (
        let index2 = 0;
        index2 < quotationDetail?.bookings[index]?.bookingItems.length;
        index2++
      ) {
        data.push(index + "" + index2);
      }
    }
    setOpenItems(data);
    setShowTNCTables(true);
    // Allow DOM update to complete before printing
    setTimeout(() => {
      window.print();
      setShowTNCTables(false);
    }, 0); // Use a minimal timeout to ensure the UI update finishes
  };

  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const contentRef = useRef(null);
  const handleTermsCheckboxChange = () => {
    setIsTermsChecked(!isTermsChecked); // Toggle the checkbox status
  };
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    const contentDiv = contentRef.current;

    function handleScroll() {
      // Check if the user has scrolled to the bottom
      if (
        contentDiv.scrollTop + contentDiv.clientHeight >=
        contentDiv.scrollHeight - 10
      ) {
        setIsScrolledToBottom(true);
      } else {
        setIsScrolledToBottom(false);
      }
    }

    contentDiv.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      contentDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSetNewCard = (newCard) => {
    setQuotationDetail((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        individualDetail: {
          ...prev.user.individualDetail,
          paymentMethod: [...prev.user.individualDetail.paymentMethod, newCard],
        },
      },
    }),);
  }

  const handleDeleteCard = (paymentMethodId) => {
    setQuotationDetail((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        individualDetail: {
          ...prev.user.individualDetail,
          paymentMethod: prev.user.individualDetail.paymentMethod.filter(
            (method) => method._id !== paymentMethodId
          ),
        },
      },
    }));
  };

  const addPaymentMethodHandler = async () => {
    const { firstName, lastName } = await extractFirstAndLastName(
      quotationDetail?.user.name
    );
    const zipCodeCheck = await getLocationByAddress(
      quotationDetail?.user.individualDetail.billingInformation.postCode,
      quotationDetail?.user.individualDetail.billingInformation.country_code
    );

    const config = {
      env: process.env.REACT_APP_FLYWIRE_ENV,
      recipientCode: process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,

      amount: 0,
      recurringType: "tokenization",
      recurringTypeRecipientCodes: [
        process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,
        process.env.REACT_APP_FLYWIRE_JPT_RECIPIENT_CODE,
      ],

      firstName: firstName,
      lastName: lastName,
      email: quotationDetail?.user.email,
      phone:
        quotationDetail?.user.individualDetail.billingInformation
          .prefix_number +
        " " +
        quotationDetail?.user.individualDetail.billingInformation.phoneNumber,
      address:
        quotationDetail?.user.individualDetail.billingInformation.address,
      city: zipCodeCheck.address_components.filter(
        (item) =>
          item.types.includes("administrative_area_level_2") ||
          item.types.includes("locality")
      )[0].long_name,
      zip: quotationDetail?.user.individualDetail.postCode,
      country:
        quotationDetail?.user.individualDetail.billingInformation.country_code,

      requestPayerInfo: false,
      requestRecipientInfo: false,

      // Control which tokenization options are available
      paymentOptionsConfig: {
        filters: {
          type: ["credit_card"],
        },
      },
      onCompleteCallback: function (args) {
        callbackFlywireAddPaymentMethod(args);
      },
      onInvalidInput: function (errors) {
        errors.forEach(function (error) {
          console.log(error.msg);
        });
      },
    };

    // setLoading(false);
    const modal = window.FlywirePayment.initiate(config);
    modal.render();
  };

  const callbackFlywireAddPaymentMethod = async (result) => {
    if (result.status === "success") {
      const updatePayment = await flywireAddPaymentMethod(result);

      if (updatePayment.status === "SUCCESS") {
        handleSetNewCard(updatePayment.data);
        handleSelectedPaymentMethod(updatePayment.data._id);
      } else {
        handleModalSelectPaymentMethod(false);
        setmodalAlertText(updatePayment.msg ?? "An error occurred while tokenize credit card.");
        setShowModalAlert(true);
      }
    }
  };

  if (error) {
    return <PageNotFound />;
  }
  return (
    <>
      <AlertModal
        handleClose={handleCloseModalAlert}
        show={showModalAlert}
        text={modalAlertText}
      />
      <Fragment>
        <div className="bg-grey-light">
          <div className="sticky-top no-print">
            <div className="px-1 px-md-5 py-2 bg-dark">
              <div className="d-flex justify-content-md-between justify-content-end align-items-center">
                <Link className="py-2 navbar-brand pe-4" to="/">
                  <img
                    className="image-fluid d-none d-md-block"
                    src={logo}
                    alt="logo"
                  />
                  <img
                    className={`image-fluid d-block d-md-none  ${`ps-0`}  ps-md-0`}
                    src={logoMobile}
                    alt="logo"
                  />
                </Link>
                <div className="">
                  <LanguageCard />
                </div>
              </div>
            </div>
          </div>
          {isShow && (
            <div className="container-md pt-5 r-mt-5-print">
              <div className="d-none d-md-block no-print">
                <div className="d-flex justify-content-between mb-2 ">
                  <div className="col no-print">
                    <div className="d-flex gap-2">
                      <button className="bg-dark rounded" onClick={handlePrint}>
                        <div className="d-flex gap-2 px-3 py-2 justify-content-center align-items center">
                          <img src={print_white_icon} alt="" srcSet="" />
                          <p className="m-0 text-white">Print</p>
                        </div>
                      </button>
                      {/* <button className="bg-dark rounded">
                  <div className="d-flex gap-2 px-3 py-2 justify-content-center align-items center">
                    <img src={download_white_icon} alt="" srcSet="" />
                      <p className="m-0 text-white">Download</p>
                    </div>
                  </button> */}
                    </div>
                  </div>
                  <div className="col no-print">
                    <div className="d-flex justify-content-end gap-2">
                      <div className="col-6">
                        <div className="d-grid">
                          {quotationDetail?.paymentStatus === "NOT_PAID" && (
                            <button
                              className="bg-gold rounded"
                              onClick={() => handleOpenTNCModal()}
                            >
                              <div className="d-flex gap-2 px-3 py-2 justify-content-center align-items center">
                                <p className="m-0 text-white">Pay Now</p>
                                <img
                                  src={arrow_right_white_icon}
                                  alt=""
                                  srcSet=""
                                />
                              </div>
                            </button>
                          )}
                        </div>
                      </div>
                      <button
                        className={`rounded ${quotationDetail?.paymentStatus === "PAID"
                          ? "bg-green"
                          : quotationDetail?.paymentStatus === "NOT_PAID"
                            ? "bg-blue"
                            : "bg-EA4335"
                          }`}
                      >
                        <div className="d-flex gap-2 px-3 py-2 ">
                          <p className="m-0 text-white">
                            {" "}
                            {quotationDetail?.paymentStatus === "PAID"
                              ? "Paid"
                              : quotationDetail?.quotationStatus === "ACTIVE" &&
                                quotationDetail?.paymentStatus === "NOT_PAID"
                                ? "Active"
                                : quotationDetail?.quotationStatus === "EXPIRED"
                                  ? "Expired"
                                  : ""}
                          </p>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* MOBILE BUTTONS */}
              <div className="d-block d-md-none no-print">
                <div className="row mb-2 ">
                  <div className="col-12  no-print">
                    <div className="d-flex gap-2">
                      <button className="bg-dark rounded" onClick={handlePrint}>
                        <div className="col d-flex gap-2 px-3 py-2 justify-content-center align-items center">
                          <img src={print_white_icon} alt="" srcSet="" />
                          <p className="m-0 text-white">Print</p>
                        </div>
                      </button>
                      {/* <button className="bg-dark rounded">
                    <div className="col d-flex gap-2 px-3 py-2 justify-content-center align-items center">
                    <img src={download_white_icon} alt="" srcSet="" />
                      <p className="m-0 text-white">Download</p>
                    </div>
                  </button> */}
                      <button
                        className={`rounded col  ${quotationDetail?.paymentStatus === "PAID"
                          ? "bg-green"
                          : quotationDetail?.paymentStatus === "NOT_PAID"
                            ? "bg-blue"
                            : "bg-EA4335"
                          }`}
                      >
                        <div className="d-flex gap-2 px-3 py-2 justify-content-center ">
                          <p className="m-0 text-white ">
                            {" "}
                            {quotationDetail?.paymentStatus === "PAID"
                              ? "Paid"
                              : quotationDetail?.quotationStatus === "ACTIVE" &&
                                quotationDetail?.paymentStatus === "NOT_PAID"
                                ? "Active"
                                : quotationDetail?.quotationStatus === "EXPIRED"
                                  ? "Expired"
                                  : ""}
                          </p>
                        </div>
                      </button>
                    </div>
                  </div>

                  <div className="col-12 mt-3  no-print">
                    <div className="d-flex justify-content-end gap-2">
                      <div className="col-12  no-print">
                        <div className="d-grid">
                          {quotationDetail?.paymentStatus === "NOT_PAID" && (
                            <button
                              className="bg-gold rounded"
                              onClick={() => payButtonHandle()}
                            >
                              <div className="d-flex gap-2 px-3 py-2 justify-content-center align-items center">
                                <p className="m-0 text-white">Pay Now</p>
                                <img
                                  src={arrow_right_white_icon}
                                  alt=""
                                  srcSet=""
                                />
                              </div>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white rounded mb-5">
                <div className="p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4
                      style={{
                        fontSize: "29px",
                        fontWeight: "900",
                        color: "#212529",
                      }}
                    >
                      Quotation
                    </h4>
                    <img src={tlf_logo_quotation} alt="" srcSet="" />
                  </div>
                </div>
                <hr className="m-0 divider" />
                <div className="p-3 mb-3">
                  <div className="row m-0">
                    <div className="col-md-4">
                      <p className=" m-0 w-700 fs-12 text-A7A7A7">TO</p>
                      <p
                        className=" m-0 w-700 fs-16 text-4E4E4E"
                        style={{ fontWeight: "700 !important" }}
                      >
                        {quotationDetail?.user.name}
                      </p>
                      <p className="text-gold text-decoration-underline">
                        {quotationDetail?.user.email}
                      </p>
                      <p className="">
                        {quotationDetail?.user.role === "individual"
                          ? quotationDetail?.user?.individualDetail
                            ?.billingInformation?.address
                          : quotationDetail?.user?.businessDetail
                            ?.billingInformation?.address}
                      </p>
                      <p className="">
                        {" "}
                        {quotationDetail?.user &&
                          getPhoneNumber(quotationDetail?.user)}
                      </p>
                    </div>
                    <div className="col-md-5">
                      <p className=" m-0 w-700 fs-12 text-A7A7A7">FROM</p>
                      <p
                        className=" m-0 w-700 fs-16 text-4E4E4E"
                        style={{ fontWeight: "700 !important" }}
                      >
                        THE LUXURY FLEET Co., Ltd.
                      </p>
                      <p className="text-gold text-decoration-underline">
                        info@theluxuryfleet.com
                      </p>
                      <p className="">
                        Dai 2 Hayashi Building 1F/2F, Kitashinagawa 1-8-20,
                        Shinagawa-ku, Tokyo 140-0001 Japan
                      </p>
                      <p className="">(+81) 3-6822-8772</p>
                    </div>
                    <div className="col-md-3">
                      <div className="d-flex ">
                        <table>
                          <tr>
                            <td className="pb-0">
                              <p className="p-0 m-0 text-7C7B7B fw-400 fs-14 text-nowrap">
                                Issue Date
                              </p>
                            </td>
                            <td className="pb-0">
                              <p className=" ms-3 mb-0 text-4E4E4E fw-400 fs-14 text-nowrap fw-400">
                                {" "}
                                {quotationDetail &&
                                  moment(quotationDetail?.issueDate).format(
                                    "MMMM D, YYYY"
                                  )}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="pb-0">
                              <p className="p-0 m-0 text-7C7B7B fw-400 fs-14 text-nowrap">
                                Quotation No.
                              </p>
                            </td>
                            <td className="pb-0">
                              <p className=" ms-3 mb-0 text-4E4E4E fw-400 fs-14 text-nowrap  fw-400">
                                {" "}
                                {quotationDetail?.quotationID}
                              </p>
                            </td>
                          </tr>
                          {/* <tr>
                          <td className="p-0 text-7C7B7B fw-400 ">Tax No.</td>
                          <td className="ps-5 px-0 py-0 pe-0 text-4E4E4E">
                            T2010401114998
                          </td>
                        </tr> */}
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 mt-3 mt-md-0">
                    <div className="col-md-4">
                      <table className="w-100 border-282828">
                        <tr className="border-282828">
                          <td rowSpan={2} className="text-center border-282828">
                            <p className="mb-2 fs-14 fw-400">VALID UNTIL</p>
                            <p className="mb-0 fs-14 fw-700 text-282828">
                              {quotationDetail &&
                                moment(quotationDetail?.expiredDate).format(
                                  "MMMM D, YYYY"
                                )}
                            </p>
                          </td>
                          <td className="text-center border-282828 bg-dark">
                            <p className="m-0 text-white fw-700 fs-14">TOTAL</p>
                          </td>
                        </tr>
                        <tr className="border-282828">
                          <td className="text-center border-282828">
                            <p className="m-0 text-282828 fw-700 fs-20">
                              {quotationDetail?.bookings
                                ? `${quotationDetail.bookings.reduce((grandTotal, item) => {
                                  const subtotal = item.bookingItems?.reduce(
                                    (subtotal, b) => subtotal + b.price * b.qty,
                                    0
                                  );
                                  return grandTotal + (subtotal || 0);
                                }, 0).toLocaleString()} JPY`
                                : '0 JPY'}
                            </p>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

                <div className=" d-none d-md-block">
                  <table className="table table-quotation-items mb-3">
                    <tr className="bg-dark">
                      <th className="align-middle py-2">
                        <b className="pb-0 ms-2">Name</b>
                        <p className="py-0 ms-2 mb-0 text-white">Description</p>
                      </th>
                      <th>Service Date</th>
                      <th>Unit Price</th>
                      <th>Qty</th>
                      <th>Amount</th>
                    </tr>

                    <>
                      {quotationDetail?.bookings.map((item, index) => (
                        <>
                          {/* Booking Type Row */}
                          <tr className="bg-grey-light border border-bottom">
                            <td colSpan={5}>
                              <p className="m-0">
                                {getBookingType(item?.bookingType, item?.isAirportTransfer)}
                              </p>
                            </td>
                          </tr>

                          {/* Booking Items */}
                          {item?.bookingItems.map((b, index) => (
                            <tr
                              key={index}
                              className={`border-bottom ${index !== 0 ? 'border-top-E4D6B3' : ''}`}
                            >
                              <td>
                                <b>{b.name}</b>
                                <p className="py-0 mb-0">{b.desc}</p>
                              </td>
                              <td>{b.serviceDate}</td>
                              <td>{`${b.price.toLocaleString()} JPY`}</td>
                              <td>{b.qty}</td>
                              <td>{`${(b.price * b.qty).toLocaleString()} JPY`}</td>
                            </tr>
                          ))}

                          {/* Subtotal */}
                          <tr className="bg-DADADA">
                            <td className="text-end pe-2" colSpan={4}>
                              SUBTOTAL
                            </td>
                            <td>
                              <b className="m-0 p-0">
                                {item?.bookingItems
                                  ? `${item.bookingItems.reduce(
                                    (subtotal, b) => subtotal + b.price * b.qty,
                                    0
                                  ).toLocaleString()} JPY`
                                  : '0 JPY'}
                              </b>
                            </td>
                          </tr>
                        </>
                      ))}

                      {/* Total */}
                      <tr className="bg-dark text-white">
                        <td colSpan={4} className="text-end">
                          <b>TOTAL</b>
                          <p className="py-0 my-0 text-white">(Tax Incl.)</p>
                        </td>
                        <td className="align-top">
                          <b className="m-0 p-0 fs-18">
                            {quotationDetail?.bookings
                              ? `${quotationDetail.bookings.reduce((grandTotal, item) => {
                                const subtotal = item.bookingItems?.reduce(
                                  (subtotal, b) => subtotal + b.price * b.qty,
                                  0
                                );
                                return grandTotal + (subtotal || 0);
                              }, 0).toLocaleString()} JPY`
                              : '0 JPY'}
                          </b>
                        </td>
                      </tr>
                    </>
                  </table>
                </div>

                <div className="d-block d-md-none">
                  <div className="d-flex bg-dark justify-content-between align-items-center p-3">
                    <div className="">
                      <b className="pb-0  text-white">Name</b>
                      <p className="py-0  mb-0 text-white">Description</p>
                    </div>
                    <p className="mb-0 text-white">Amount</p>
                  </div>
                  {quotationDetail?.bookings.map((item, index) => (
                    <>
                      <div className="bg-grey-light p-3">
                        <p className="m-0 ">
                          {getBookingType(
                            item?.bookingType,
                            item?.isAirportTransfer
                          )}
                        </p>
                      </div>
                      {item?.bookingItems.map((b, bIndex) => {
                        return (
                          <div className="p-3 border-bottom">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="">
                                <b>{b.name}</b>
                                <p className="py-0 mb-0">{b.desc}</p>
                              </div>
                              {!openItems.includes(index + "" + bIndex) && (
                                <p className="m-0 p-0 text-nowrap">
                                  {`${item.totalPrice.toLocaleString()} JPY`}
                                </p>
                              )}
                            </div>
                            {openItems.includes(index + "" + bIndex) && (
                              <>
                                <div className="border-bottom border-top mt-2">
                                  <div className="py-2">
                                    <div className="d-flex justify-content-between">
                                      <p className="m-0 fs-13">Service Date</p>
                                      <p className="m-0 fs-13">{b.serviceDate}</p>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                      <p className="m-0 fs-13">Unit Price</p>
                                      <p className="m-0 fs-13">
                                        {`${b.price.toLocaleString()} JPY`}
                                      </p>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                      <p className="m-0 fs-13">Qty</p>
                                      <p className="m-0 fs-13">{b.qty}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between mt-2">
                                  <b>Amount</b>
                                  <b>{`${(
                                    b.price * b.qty
                                  ).toLocaleString()} JPY`}</b>
                                </div>
                              </>
                            )}
                            <div className="text-center">
                              {openItems.includes(index + bIndex) ? (
                                <img
                                  src={collapse_up_icon}
                                  onClick={() => toggleOpen(index + "" + bIndex)}
                                />
                              ) : (
                                <img
                                  src={collapse_down_icon}
                                  onClick={() => toggleOpen(index + "" + bIndex)}
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                      <div className="bg-DADADA p-3 ">
                        <div className="d-flex justify-content-between">
                          <p className="m-0">SUBTOTAL</p>
                          <b className="m-0 p-0">
                            {item?.bookingItems
                              ? `${item.bookingItems.reduce(
                                (subtotal, b) => subtotal + b.price * b.qty,
                                0
                              ).toLocaleString()} JPY`
                              : '0 JPY'}
                          </b>
                        </div>
                      </div>
                    </>
                  ))}
                  <div className="bg-dark text-white p-3 ">
                    <div className="d-flex justify-content-between">
                      <p className="m-0 text-white">TOTAL</p>
                      <b className="m-0 p-0">
                        {quotationDetail?.bookings
                          ? `${quotationDetail.bookings.reduce((grandTotal, item) => {
                            const subtotal = item.bookingItems?.reduce(
                              (subtotal, b) => subtotal + b.price * b.qty,
                              0
                            );
                            return grandTotal + (subtotal || 0);
                          }, 0).toLocaleString()} JPY`
                          : '0 JPY'}
                      </b>
                    </div>
                  </div>
                </div>

                <div className="p-3">
                  {quotationDetail?.bookings.map((item, index) => (
                    <>
                      {item?.message?.length > 0 ? (
                        <>
                          {index === 0 && (
                            <p className="noto" style={{ fontWeight: "700" }}>
                              Notes
                            </p>
                          )}
                          <p className="fs-14 fw-700 m-0 text-#4E4E4E">
                            {getBookingType(item?.bookingType)} (
                            {
                              item?.bookingItems.find(
                                (item) => item.type === "vehicle"
                              )?.serviceDate
                            }
                            )
                          </p>
                          {item?.message ? (
                            item.message.map((m) => <p>{m.note}</p>)
                          ) : (
                            <p>-</p>
                          )}{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                </div>
              </div>
              <br />

              <div className={`px-4 ${showTNCTables ? "d-block" : "d-none"}`}>
                <TNCTables />
              </div>
            </div>
          )}
        </div>

        {showModal && (
          <>
            <CustomerLogin
              useModal={true}
              modalId={"login"}
              openModal={showModal}
              autoOpen={true}
              isQuotation={true}
            />

            <CustomerRegister useModal={true} modalId={"register"} />
          </>
        )}


        {showModaSelectPaymentMethod && quotationDetail != null && <PaymentMethodSelectionModal
          show={showModaSelectPaymentMethod}
          title={t("PaymentMethodSelection.Title")}
          size="modal-lg"
          onClose={() => handleModalSelectPaymentMethod(false)}
          paymentMethods={quotationDetail?.user.individualDetail.paymentMethod}
          selectedPaymentMethod={selectedPaymentMethod}
          handleSelectedPaymentMethod={handleSelectedPaymentMethod}
          payButtonAuthenticatedUser={payButtonAuthenticatedUser}
          quotationDetail={quotationDetail}
          handleSetNewCard={handleSetNewCard}
          handleDeleteCard={handleDeleteCard}
          addPaymentMethodHandler={addPaymentMethodHandler}
        ></PaymentMethodSelectionModal>}

        <QuotationModal
          show={showTNCModal}
          size="modal-xl"
          centered={true}
          onClose={handleCloseTNCModal}
          title={"Terms & Conditions"}
        >
          <div
            className="overflow-auto"
            ref={contentRef}
            style={{ height: "500px" }}
          >
            <TNCTables />
          </div>
          <hr className="my-4 divider" />
          <div
            class="form-check form-check-inline mb-3"
            data-tip
            data-for="tncTooltip"
          >
            <label class="form-check-label fs-14">
              <input
                class="form-check-input"
                type="checkbox"
                name=""
                id=""
                value="checkedValue"
                checked={isTermsChecked}
                onChange={handleTermsCheckboxChange}
                disabled={isScrolledToBottom ? false : true}
              />{" "}
              {t("NewCheckout.TermsAndCondition.Checkbox")}
            </label>
            <ReactTooltip
              id="tncTooltip"
              backgroundColor="#4A4A4A"
              textColor="#FFFFFF"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: t("NewCheckout.TncTooltip"),
                }}
              ></span>
            </ReactTooltip>
          </div>

          <div className="d-grid">
            <button
              className="btn bg-gold text-white rounded py-2"
              onClick={payButtonHandle}
              disabled={!isTermsChecked}
            >
              <div className="d-flex justify-content-center align-items-center text-white gap-2">
                {t("Proceed to payment")}
                <img src={arrow_right_white_icon} alt="" srcSet="" />
              </div>
            </button>
          </div>
        </QuotationModal>
      </Fragment>
    </>
  );
};
